import Machining from '../../assets/images/symbols/black/machining.jpg';
import Moving from '../../assets/images/symbols/black/moving.jpg';
import Positioning from '../../assets/images/symbols/black/positioning.jpg';
import Processing from '../../assets/images/symbols/black/processing.jpg';
import Pumping from '../../assets/images/symbols/black/pumping.jpg';
import { ApplicationType } from '../enums';
import { SelectableApplication } from '../models';

export const SELECTABLE_APPLICATIONS: SelectableApplication[] = [
    { name: ApplicationType.Pumping, image: Pumping },
    { name: ApplicationType.Positioning, image: Positioning },
    { name: ApplicationType.Processing, image: Processing },
    { name: ApplicationType.Machining, image: Machining },
    { name: ApplicationType.Moving, image: Moving }
];
