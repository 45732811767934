export const INITIAL_LINE_VALUES = {
    minAndMaxPowers: {
        maxPowerHp: 0,
        maxPowerKw: 0,
        minPowerHp: 0,
        minPowerKw: 0
    },
    communications: [],
    applications: [],
    ratedVoltages: []
};
