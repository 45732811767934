import G115D from '../../assets/images/engines/G115D.png';
import G120 from '../../assets/images/engines/G120.png';
import G120C from '../../assets/images/engines/G120C.png';
import G120D from '../../assets/images/engines/G120D.png';
import G120P from '../../assets/images/engines/G120P.png';
import G120X from '../../assets/images/engines/G120X.png';
import G130 from '../../assets/images/engines/G130.png';
import G150 from '../../assets/images/engines/G150.png';
import G180 from '../../assets/images/engines/G180.png';
import S120 from '../../assets/images/engines/S120.png';
import S120High from '../../assets/images/engines/S120_frequency_converter.png';
import S120Servo from '../../assets/images/engines/S120_servo_converter.png';
import S120CM from '../../assets/images/engines/S120CM.png';
import S120M from '../../assets/images/engines/S120M.png';
import S150 from '../../assets/images/engines/S150.png';
import S200 from '../../assets/images/engines/S200.png';
import S210 from '../../assets/images/engines/S210.png';
import V20 from '../../assets/images/engines/V20.png';
import V90 from '../../assets/images/engines/V90.png';
import RA80 from '../../assets/images/engines/6RA80.png';
import DCM from '../../assets/images/engines/DCM.png';
import G110D from '../../assets/images/engines/G110D.png';
import G110M from '../../assets/images/engines/G110M.png';
import G220 from '../../assets/images/engines/G220.png';

export const getLineImagePaths = (imageName: string, formattedCategory: string) => {
    if (imageName === 'S120') {
        formattedCategory === 'high performance converter' ? imageName += formattedCategory : null;
    }

    switch (imageName) {
        case 'G115D':
            return G115D;
        case 'G120':
            return G120;
        case 'G120C':
            return G120C;
        case 'G120D':
            return G120D;
        case 'G120P':
            return G120P;
        case 'G120X':
            return G120X;
        case 'G130':
            return G130;
        case 'G150':
            return G150;
        case 'G180':
            return G180;
        case 'S120':
            return S120;
        case 'S120high performance converter':
            return S120High;
        case 'S120servo converter':
            return S120Servo;
        case 'S120CM':
            return S120CM;
        case 'S120M':
            return S120M;
        case 'S150':
            return S150;
        case 'S200':
            return S200;
        case 'S210':
            return S210;
        case 'V20':
            return V20;
        case 'V90':
            return V90;
        case '6RA80':
            return RA80;
        case 'DCM':
            return DCM;
        case 'G110D':
            return G110D;
        case 'G110M':
            return G110M;
        case 'G220':
            return G220;
    }
};
