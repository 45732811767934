import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useOpenAnimations } from '../../hooks';
import ContentContainer from './ContentContainer';
import Icon, { IconType } from './Icon';

export enum BannerType {
    Error,
    Info,
    Success,
    Warning
}

interface BannerProps {
    text: string;
    type: BannerType;
    dark?: boolean;
    onDisappear?: () => void;
}

const Banner = ({ dark, text, type, onDisappear }: BannerProps) => {
    const [visible, setVisible] = useState(false);
    const [animationClass, onAnimationEnd, contentRef, height] = useOpenAnimations<HTMLDivElement>(visible);

    useEffect(() => {
        setVisible(true);
    }, []);

    const handleAnimationEnd = () => {
        onAnimationEnd();

        if (onDisappear && !visible) {
            onDisappear();
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setVisible(false);
        }
    };

    const getTypeClass = () => {
        switch (type) {
            case BannerType.Error:
                return 'error';
            case BannerType.Info:
                return 'info';
            case BannerType.Success:
                return 'success';
            case BannerType.Warning:
                return 'warning';
        }
    };

    const getIconType = () => {
        switch (type) {
            case BannerType.Error:
                return IconType.Error;
            case BannerType.Info:
                return IconType.Information;
            case BannerType.Success:
                return IconType.Success;
            case BannerType.Warning:
                return IconType.Warning;
        }
    };

    return (
        <div className={`banner ${getTypeClass()} ${animationClass} ${dark ? 'dark' : ''}`} style={{ height }} onAnimationEnd={handleAnimationEnd}>
            <div className='banner-content-container'>
                <ContentContainer>
                    <div ref={contentRef} className='banner-content'>
                        <Icon type={getIconType()} />
                        <div className='banner-text'>
                            {text}
                        </div>
                        {onDisappear &&
                            <div className='banner-close-button' tabIndex={0} onClick={() => setVisible(false)} onKeyDown={handleKeyDown}>
                                <Icon type={IconType.Close} />
                            </div>
                        }
                    </div>
                </ContentContainer>
            </div>
        </div>
    );
};

export default Banner;
