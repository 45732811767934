import { ApiBase } from '.';
import { LineData, LineValues, ServiceDataResult } from '../models';

export class Api extends ApiBase {
    static loadValues = () => Api.get('api/converter/values') as Promise<ServiceDataResult<LineValues>>;

    static getAllLineItems = () => Api.get('api/converter/getalllineitems') as Promise<ServiceDataResult<LineData[]>>;

    static isProduction = () => Api.get('api/home/production') as Promise<ServiceDataResult<boolean>>;
}
