import React, { ReactText } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators, ApplicationState } from '../../store';
import { TagProps } from '../common/Tag';
import { SectionColor } from '../common/Section';
import TagBar from '../common/TagBar';
import { getTranslations, INITIAL_POWERS } from '../../constants';
import { useTranslate } from '../../hooks';

interface SelectorTagBarProps {
    backgroundColor: SectionColor;
}

const SelectorTagBar = ({ backgroundColor }: SelectorTagBarProps) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const translations = getTranslations();
    const filter = useSelector((state: ApplicationState) => state.selector.filter);
    const hiddenIds = useSelector((state: ApplicationState) => state.selector.hiddenIds);

    const getTagProps = (text: string, onClick: () => void): TagProps => ({ text, onClick, close: true });

    const handlePowerReset = () => dispatch(actionCreators.setFilter({ powerMin: INITIAL_POWERS.powerMin, powerMax: INITIAL_POWERS.powerMax }));

    const handleRemovedLineDataReset = () => {
        dispatch(actionCreators.addNewIds(hiddenIds));
        dispatch(actionCreators.clearHiddenIds());
    };

    const tags: TagProps[] = [
        ...hiddenIds.length
            ? [getTagProps(translate(translations.lvconverters.filterActive), handleRemovedLineDataReset)]
            : [],
        ...filter.application
            ? [getTagProps(filter.application, () => dispatch(actionCreators.setFilter({ application: '' })))]
            : [],
        ...filter.powerMin && filter.powerMax
            ? [getTagProps(`${filter.powerMin} - ${filter.powerMax} ${filter.isKwSelected ? 'kW' : 'hp'}`, handlePowerReset)]
            : [],
        ...filter.phases && filter.voltageRange
            ? [getTagProps(`${filter.phases} ${filter.voltageRange} V`, () => dispatch(actionCreators.setFilter({ phases: '', voltageRange: '' })))]
            : [],
        ...filter.communication
            ? [getTagProps(filter.communication, () => dispatch(actionCreators.setFilter({ communication: '' })))]
            : [],
        ...filter.category
            ? [getTagProps(filter.category, () => dispatch(actionCreators.setFilter({ category: '' })))]
            : []
    ];

    return (
        <div className='selector-tag-bar'>
            <TagBar tags={tags} backgroundColor={backgroundColor}/>
        </div>
    );
};

export default SelectorTagBar;
