import React, { useEffect, useState } from 'react';
import { convertToDecimalString } from '../../services';
import TextInput, { InputProps } from './TextInput';

interface NumberInputProps extends InputProps {
    decimal?: boolean
    negative?: boolean
    percentageFromMultiplier?: boolean
}

const NumberInput = ({ decimal, negative, percentageFromMultiplier, value, onChange, name, ...rest }: NumberInputProps) => {
    useEffect(() => {
        handleNumberChange(value, name);
    }, [value]);

    const percentageValueString = (multiplierValue: string) => multiplierValue.includes('.')
        ? convertToDecimalString(multiplierValue, 'percentage')
        : multiplierValue === '' ? '' : (Number(multiplierValue) * 100).toString();

    const nonZeroValue = value === '0' ? '' : value;
    const [stringValue, setStringValue] = useState(percentageFromMultiplier ? percentageValueString(nonZeroValue) : nonZeroValue);

    const changeNumber = (value: string, name: string) => !isNaN(Number(value)) && onChange &&
        onChange(
            percentageFromMultiplier
                ? convertToDecimalString(value, 'multiplier')
                : value,
            name
        );

    const handleNumberChange = (value: string, name: string) => {
        const cleanedValue = decimal
            ? negative
                ? value
                : value.replace('-', '')
            : negative
                ? value.replace('.', '')
                : value.replace('.', '').replace('-', '');

        const displayRegex = decimal
            ? negative
                ? /^(0{1}(?!0{1,})|[1-9]{1}|-{1}0{1}(?!0{1,})|-{1}(?!0{2,}))([0-9]{0,})((\.[0-9]{0,}|[0-9]{0,})|^0{1})$/
                : /^(0{1}(?!0{1,})|[1-9]{1})([0-9]{0,})((\.[0-9]{0,}|[0-9]{0,})|0{1})$/
            : negative
                ? /^([1-9]{1}|-{1}(?!0{1,}))([0-9]{0,})$/
                : /^([1-9]{1})([0-9]{0,})$/;

        const saveRegex = decimal
            ? negative
                ? /^-{0,1}\d+(\.\d+)?$/
                : /^\d+(\.\d+)?$/
            : negative
                ? /^-{0,1}\d+$/
                : /^\d+$/;

        setStringValue(value.length ? displayRegex.exec(value)?.input ?? stringValue : '');

        changeNumber(
            value.length
                ? saveRegex.exec(value)?.input ?? (percentageFromMultiplier ? percentageValueString(value) : cleanedValue)
                : '0',
            name
        );
    };

    return (
        <TextInput
            {...rest}
            name={name}
            value={stringValue}
            onChange={handleNumberChange}
        />
    );
};

export default NumberInput;
