export const convertToDecimalString = (stringValue: string, percentage?: 'percentage' | 'multiplier') => {
    const decimalLength = stringValue.split('.')[1]?.length;
    const lastCharacter = stringValue[stringValue.length - 1];
    const decimalString = percentage
        ? percentage == 'percentage'
            ? `${(Number(stringValue) * 100).toFixed(decimalLength >= 2 ? decimalLength - 2 : decimalLength)}${lastCharacter === '0' ? '0' : ''}`
            : `${(Number(stringValue) / 100).toFixed(stringValue.includes('.') ? decimalLength + 2 : 2)}${lastCharacter === '0' ? '0' : ''}`
        : Number(stringValue).toFixed(decimalLength);

    return decimalString;
};
