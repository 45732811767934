import { Translations } from '../models';

export const getTranslations = (): typeof translation extends Translations ? typeof translation : never => {
    const translation = {
        'header': {
            'title': [
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS',
                'Easy Selection SINAMICS'
            ],
            'language': {
                'title': [
                    'Language',
                    'Sprache',
                    'Idioma',
                    'Langue',
                    'Lingua',
                    'Jazyk',
                    'Taal',
                    'Język',
                    'Idioma',
                    'Язык',
                    'Dil',
                    '语言'
                ],
                'subtitle': [
                    'Set this page to',
                    'Stellen Sie diese Seite um auf',
                    'Cambiar el idioma de esta página a',
                    'Régler cette page pour',
                    'Imposta questa pagina in',
                    'Nastavit tuto stránku na',
                    'Stel deze pagina in op',
                    'Zmień język strony na',
                    'Configurar esta página para',
                    'Настроить эту страницу на',
                    'Sayfa dilini ayarlayın',
                    '将此页设为'
                ]
            },
            'languages': {
                'en': [
                    'English',
                    'Englisch',
                    'Inglés',
                    'Anglais',
                    'Inglese',
                    'Angličtina',
                    'Engels',
                    'Angielski',
                    'Inglês',
                    'Английский язык',
                    'İngilizce',
                    '英语'
                ],
                'de': [
                    'German',
                    'Deutsch',
                    'Alemán',
                    'Allemand',
                    'Tedesco',
                    'Němčina',
                    'Duits',
                    'Niemiecki',
                    'Alemão',
                    'Немецкий язык',
                    'Almanca',
                    '德语'
                ],
                'es': [
                    'Spanish',
                    'Spanisch',
                    'Español',
                    'Espagnol',
                    'Spagnolo',
                    'Španělština',
                    'Spanish',
                    'hiszpański',
                    'Espanhol',
                    'Испанский',
                    'İspanyolca',
                    '西班牙语'
                ],
                'fr': [
                    'French',
                    'Französisch',
                    'Francés',
                    'Français',
                    'Francese',
                    'Francouzština',
                    'French',
                    'francuski',
                    'Francês',
                    'Французский',
                    'Fransızca',
                    '法语'
                ],
                'it': [
                    'Italian',
                    'Italienisch',
                    'Italiano',
                    'Italien',
                    'Italiano',
                    'Italština',
                    'Italian',
                    'włoski',
                    'Italiano',
                    'Итальянский',
                    'İtalyanca',
                    '意大利语'
                ],
                'cz': [
                    'Czech',
                    'Tschechisch',
                    'Checo',
                    'Tchèque',
                    'Ceco',
                    'Čeština',
                    'Czech',
                    'czeski',
                    'Checo',
                    'Чешский',
                    'Çekçe',
                    '捷克语'
                ],
                'nl': [
                    'Dutch',
                    'Niederländisch',
                    'Neerlandés',
                    'Néerlandais',
                    'Olandese',
                    'Holandština',
                    'Dutch',
                    'niderlandzki',
                    'Holandês',
                    'Голландский',
                    'Flemenkçe',
                    '荷兰语'
                ],
                'pl': [
                    'Polish',
                    'Polnisch',
                    'Polaco',
                    'Polonais',
                    'Polacco',
                    'Polština',
                    'Polish',
                    'polski',
                    'Polaco',
                    'Польский',
                    'Lehçe',
                    '波兰语'
                ],
                'pt': [
                    'Portuguese',
                    'Portugiesisch',
                    'Portugués',
                    'Portugais',
                    'Portoghese',
                    'Portugalština',
                    'Portuguese',
                    'portugalski',
                    'Português',
                    'Португальский',
                    'Portekizce',
                    '葡萄牙语'
                ],
                'ru': [
                    'Russian',
                    'Russisch',
                    'Ruso',
                    'Russe',
                    'Russo',
                    'Ruština',
                    'Russian',
                    'rosyjski',
                    'Russo',
                    'Русский',
                    'Rusça',
                    '俄罗斯语'
                ],
                'tr': [
                    'Turkish',
                    'Türkisch',
                    'Turco',
                    'Turc',
                    'Turco',
                    'Turečtina',
                    'Turkish',
                    'turecki',
                    'Turco',
                    'Турецкий',
                    'Türkçe',
                    '土耳其语'
                ],
                'cn': [
                    'Chinese',
                    'Chinesisch',
                    'Chino',
                    'Chinois',
                    'Cinese',
                    'Čínština',
                    'Chinese',
                    'chiński',
                    'Chinês',
                    'Китайский',
                    'Çince',
                    '中文'
                ]
            },
            'skipToMainContent': [
                'Skip to main content',
                'Skip to main content'
            ],
            'support': {
                'title': [
                    'Support',
                    'Support',
                    'Soporte',
                    'Assistance',
                    'Supporto',
                    'Podpora',
                    'Ondersteuning',
                    'Wsparcie',
                    'Apoio',
                    'Поддержка',
                    'Destek',
                    '支持'
                ],
                'problem': [
                    'Report a problem',
                    'Problem melden',
                    'Notificar un problema',
                    'Signaler un problème',
                    'Segnala un problema',
                    'Ohlášení problému',
                    'Meld een probleem',
                    'Zgłoś problem',
                    'Relatar um problema',
                    'Сообщить о проблеме',
                    'Sorun bildirin',
                    '报告问题'
                ],
                'business': [
                    'E-Business',
                    'E-Business',
                    'E-Business',
                    'E-Business',
                    'E-business',
                    'E-Business',
                    'E-business',
                    'E-Business',
                    'E-Business',
                    'Сетевой бизнес',
                    'E-İşletme',
                    '电子商务'
                ]
            }
        },
        'lvconverters': {
            'selectApplication': [
                'select application',
                'Anwendung auswählen',
                'Seleccionar aplicación',
                'sélectionner application',
                'Seleziona applicazione',
                'Volba aplikace',
                'selecteer een toepassing',
                'Wybierz aplikację',
                'selecionar aplicação',
                'Выбрать приложение',
                'uygulama seçin',
                '选择应用'
            ],
            'selection': {
                'title': [
                    'Converter Features',
                    'Umrichterfunktionen',
                    'Características del convertidor',
                    'Caractéristiques du variateur',
                    'Caratteristiche del convertitore',
                    'Vlastnosti měniče',
                    'Kenmerken omvormer',
                    'Właściwości przekształtników',
                    'Características do conversor',
                    'Параметры преобразователя',
                    'Konvertör Özellikleri',
                    '变频器特性'
                ],
                'ratedPower': [
                    'Rated power',
                    'Bemessungsleistung',
                    'Potencia nominal',
                    'Puissance assignée',
                    'Potenza nominale',
                    'Jmenovitý výkon',
                    'Nominaal vermogen',
                    'Moc znamionowa',
                    'Potência nominal',
                    'Номинальная мощность',
                    'Anma gücü',
                    '额定功率'
                ],
                'ratedVoltage': [
                    'Rated voltage',
                    'Anschlussspannung',
                    'Tensión nominal',
                    'Tension assignée',
                    'Tensione nominale',
                    'Jmenovité napětí',
                    'Nominale spanning',
                    'Napięcie znamionowe',
                    'Tensão nominal',
                    'Номинальное напряжение',
                    'Anma voltajı',
                    '额定电压'
                ],
                'communication': [
                    'Communication',
                    'Kommunikation',
                    'Comunicación',
                    'Communication',
                    'Comunicazione',
                    'Komunikace',
                    'Communicatie',
                    'Funkcje komunikacyjne',
                    'Comunicação',
                    'Связь',
                    'İletişim',
                    '通讯'
                ]
            },
            'item': {
                'powerRange': [
                    'Power range',
                    'Leistungsbereich',
                    'Rango de potencia',
                    'Plage de puissance',
                    'Gamma di potenza',
                    'Výkonový rozsah',
                    'Vermogensbereik',
                    'Zakres mocy',
                    'Intervalo de potência de',
                    'Диапазон мощности',
                    'Güç aralığı',
                    '功率范围'
                ],
                'typeofProtection': {
                    'title': [
                        'Type of Protection',
                        'Schutzart',
                        'Tipo de protección',
                        'Type de protection',
                        'Grado di protezione',
                        'Typ ochrany',
                        'Beveiligingstype',
                        'Rodzaj ochrony',
                        'Tipo de proteção',
                        'Степень защиты',
                        'Koruma Tipi',
                        '防爆型式'
                    ],
                    'ulOpenTypeUlType12': [
                        'UL open type, UL type 12',
                        'UL open type, UL type 12',
                        'UL open type, UL type 12',
                        'UL open type, UL type 12',
                        'UL open type, UL type 12',
                        'UL open type, UL type 12',
                        'UL open type, UL type 12',
                        'UL open type, UL type 12',
                        'UL open type, UL type 12',
                        'UL open type, UL type 12',
                        'UL open type, UL type 12',
                        'UL open type, UL type 12'
                    ],
                    'ulOpenType': [
                        'UL open type',
                        'UL Open Type',
                        'UL open type',
                        'UL Open Type',
                        'UL Open Type',
                        'Otevřený typ podle UL',
                        'UL open type',
                        'Otwarty standard UL',
                        'UL tipo aberto',
                        'UL открытого типа',
                        'UL açık tip',
                        'UL open type'
                    ],
                    'ulOpenTypeToIp55': [
                        'UL open type IP55',
                        'UL Open Type bis IP55',
                        'UL open type hasta IP55',
                        'UL Open Type jusqu\'à IP55',
                        'UL Open Type fino a IP55',
                        'Otevřený typ podle UL do IP55',
                        'UL open type tot IP55',
                        'Otwarty standard UL IP55',
                        'UL tipo aberto bis IP55',
                        'UL открытого типа до IP55',
                        'UL açık tip bis IP55',
                        'UL open type IP55 以下'
                    ],
                    'ulType12': [
                        'UL type 12',
                        'UL Typ 12',
                        'UL type 12',
                        'UL Type 12',
                        'UL Type 12',
                        'Typ 12 podle UL',
                        'UL type 12',
                        'Standard UL 12',
                        'UL tipo 12',
                        'UL тип 12',
                        'UL tip 12',
                        'UL 12 型'
                    ],
                    'ulType3': [
                        'UL type 3',
                        'UL Typ 3',
                        'UL type 3',
                        'UL Type 3',
                        'UL Type 3',
                        'Typ 3 podle UL',
                        'UL type 3',
                        'Standard UL 3',
                        'UL tipo 3',
                        'UL тип 3',
                        'UL tip 3',
                        'UL 3 型'
                    ],
                    'ulOpenTypeIp55': [
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP55 / UL type 12 IP20, IP21, IP23, IP43, IP54'
                    ],
                    'ulOpenTypeIp20Ip21Ip23Ip43Ip54': [
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54',
                        'UL open type IP20, IP21, IP23, IP43, IP54'
                    ],
                    'ulType3UlType12': [
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12',
                        'UL type 3, UL type 12'
                    ]
                },
                'communication': [
                    'Communication',
                    'Kommunikation',
                    'Comunicación',
                    'Communication',
                    'Comunicazione',
                    'Komunikace',
                    'Communicatie',
                    'Funkcje komunikacyjne',
                    'Comunicação',
                    'Связь',
                    'İletişim',
                    '通讯'
                ],
                'driveConcept': [
                    'Drive concept',
                    'Antriebskonzept',
                    'Solución de accionamiento',
                    'Concept d\'entraînement',
                    'Sistema di azionamento',
                    'Koncepce pohonu',
                    'Aandrijfconcept',
                    'Koncepcja napędu',
                    'Conceito da drive',
                    'Концепция привода',
                    'Sürücü konsepti',
                    '驱动方案'
                ],
                'controlModes': {
                    'title': [
                        'Control Modes',
                        'Regelungsarten',
                        'Modos de control',
                        'Modes de commande',
                        'Modalità di regolazione',
                        'Režimy regulace',
                        'Besturingsmodi',
                        'Tryby sterowania',
                        'Modos de controlo',
                        'Режимы управления',
                        'Kontrol Modları',
                        '控制模式'
                    ],
                    'servoControlWithEncoder': [
                        'Servo control with encoder',
                        'Servoregelung mit Geber',
                        'Servorregulación con encóder',
                        'Servocommande avec codeur',
                        'Servoregolazione con encoder',
                        'Servoregulace se snímačem',
                        'Servoregeling met encoder',
                        'Sterowanie napędem serwo za pomocą enkodera',
                        'Controlo servo com encoder',
                        'Сервоуправление с энкодером',
                        'Enkoderli servo kontrolü',
                        '带编码器伺服控制'
                    ],
                    'vfLinearSquareLawFccEco': [
                        'V / f (linear, square law, FCC, ECO)',
                        'V / f (linear, quadratisch, FCC, ECO)',
                        'V / f (lineal, cuadrática, FCC, ECO)',
                        'V / f (lineaire, quadratique, FCC, ECO)',
                        'V / f (lineare, quadratica, FCC, ECO)',
                        'V / f (lineární, kvadratický, FCC, ECO)',
                        'V / f (lineair, kwadratisch, FCC, ECO)',
                        'V / f (liniowe, kwadratowe, FCC, ECO)',
                        'V / f (lei do quadrado, linear, FCC, ECO)',
                        'V / f (линейная, квадратичная зависимость, управление потокосцеплением FCC, экономичный ECO)',
                        'V / f (doğrusal, karesel, FCC, ECO)',
                        'V / f（直线、平方律、FCC、ECO)'
                    ],
                    'vfLinearSquareLawSensorlessVectorControlSlvc': [
                        'V / f (linear, square law), sensorless vector control (SLVC)',
                        'V / f (linear, quadratisch), geberlose Vektorregelung (SLVC)',
                        'V / f (lineal, cuadrática), regulación vectorial sin encóder (SLVC)',
                        'V / f (linéaire, quadratique), régulation vectorielle sans capteur (SLVC)',
                        'V / f (lineare, quadratica), regolazione vettoriale senza sensore (SLVC)',
                        'V / f (lineární, kvadratický), vektorová regulace bez snímače (SLVC)',
                        'V / f (lineair, kwadratisch), sensorloze vectorregeling (SLVC)',
                        'V / f (liniowe, kwadratowe), bezczujnikowy układ sterowania wektorowego (SLVC)',
                        'V / f (linear, lei do quadrado), controlo de vetores sem sensores (SLVC)',
                        'V / f (линейная, квадратичная зависимость), бессенсорное векторное управление (SLVC)',
                        'V / f (doğrusal, karesel), sensörsüz vektör kontrolü (SLVC)',
                        'V / f（线性，平方律），无传感器矢量控制（SLVC)'
                    ],
                    'vfLinearSquareLawVectorControlWithAndWithoutEncoderVcSlvc': [
                        'V / f (linear, square law), vector control with and without encoder (VC, SLVC)',
                        'V / f (linear, quadratisch), Vektorregelung mit und ohne Geber (VC, SLVC)',
                        'V / f (lineal, cuadrática), regulación vectorial con y sin encóder (VC, SLVC)',
                        'V / f (linéaire, quadratique), régulation vectorielle avec et sans codeur (VC, SLVC)',
                        'V / f (lineare, quadratica), regolazione vettoriale con e senza encoder (VC, SLVC)',
                        'V / f (lineární, kvadratický), vektorová regulace s a bez snímače (VC, SLVC)',
                        'V / f (lineair, kwadratisch), vectorregeling met en zonder encoder (VC, SLVC)',
                        'V / f (liniowe, kwadratowe), układ sterowania wektorowego z enkoderem i bez enkodera (VC, SLVC)',
                        'V / f (linear, lei do quadrado), controlo de vetores com e sem encoder (VC, SLVC)',
                        'V / f (линейная, квадратичная зависимость), векторное управление с энкодером и без него (VC, SLVC)',
                        'V / f (doğrusal, karesel), enkoderli ve enkodersiz vektör kontrolü (VC, SLVC)',
                        'V / f（线性，平方律），带/不带编码器的矢量控制（VC、SLVC)'
                    ],
                    'vfLinearSquareLawFccEcoSensorlessVectorControlSlvc': [
                        'V / f (linear, square law, FCC, ECO), sensorless vector control (SLVC)',
                        'V / f (linear, quadratisch, FCC, ECO), geberlose Vektorregelung (SLVC)',
                        'V / f (lineal, ley cuadrada, FCC, ECO), control vectorial sin sensores (SLVC)',
                        'V / f (linéaire, quadratique, FCC, ECO), régulation vectorielle sans capteur (SLVC)',
                        'V / f (lineare, quadratica, FCC, ECO), regolazione vettoriale senza sensore (SLVC)',
                        'V / f (lineární, kvadratický, FCC, ECO), vektorová regulace bez snímače (SLVC)',
                        'V / f (lineair, kwadratisch, FCC, ECO), sensorloze vectorregeling (SLVC)',
                        'V / f (liniowe, kwadratowe, FCC, ECO), bezczujnikowy układ sterowania wektorowego (SLVC)',
                        'V / f (linear, lei do quadrado, FCC, ECO), controlo de vetores sem sensores (SLVC)',
                        'V / f (линейная, квадратичная зависимость, управление потокосцеплением FCC, экономичный ECO), бессенсорное векторное управление (SLVC)',
                        'V / f (doğrusal, karesel, FCC, ECO), sensörsüz vektör kontrolü (SLVC)',
                        'V / f (线性，平方律，FCC，ECO），无传感器矢量控制（SLVC)'
                    ],
                    'vfLinearSquareLawFccEcoVectorControlWithAndWithoutEncoderVcSlvc': [
                        'V / f (linear, square law, FCC, ECO), vector control with and without encoder (VC, SLVC)',
                        'V / f (linear, quadratisch, FCC, ECO), Vektorregelung mit und ohne Geber (VC, SLVC)',
                        'V / f (lineal, ley cuadrada, FCC, ECO), control vectorial con y sin codificador (VC, SLVC)',
                        'V / f (linéaire, quadratique, FCC, ECO), régulation vectorielle avec et sans codeur (VC, SLVC)',
                        'V / f (lineare, quadratica, FCC, ECO), regolazione vettoriale con e senza encoder (VC, SLVC)',
                        'V / f (lineární, kvadratický, FCC, ECO), vektorová regulace s a bez snímače (VC, SLVC)',
                        'V / f (lineair, kwadratisch, FCC, ECO), vectorregeling met en zonder encoder (VC, SLVC)',
                        'V / f (liniowe, kwadratowe, FCC, ECO), układ sterowania wektorowego z enkoderem i bez enkodera (VC, SLVC)',
                        'V / f (linear, lei do quadrado, FCC, ECO), controlo de vetores com e sem encoder (VC, SLVC)',
                        'V / f (линейная, квадратичная зависимость, управление потокосцеплением FCC, экономичный ECO), векторное управление с энкодером и без него (VC, SLVC)',
                        'V / f (doğrusal, karesel, FCC, ECO), enkoderli ve enkodersiz vektör kontrolü (VC, SLVC)',
                        'V / f (线性，平方律，FCC，ECO），带/不带编码器的矢量控制（VC、SLVC)'
                    ],
                    'vfLinearSquareLawFccEcoVectorControlWithAndWithoutEncoderVcSlvcServoControlWithEncoder': [
                        'V / f (linear, square law, FCC, ECO), vector control with and without encoder (VC, SLVC), servo control with encoder',
                        'V / f (linear, quadratisch, FCC, ECO), Vektorregelung mit und ohne Geber (VC, SLVC), Servoregelung mit Geber',
                        'V / f (lineal, ley cuadrada, FCC, ECO), control vectorial con y sin codificador (VC, SLVC), servocontrol con codificador',
                        'V / f (linéaire, quadratique, FCC, ECO), régulation vectorielle avec et sans codeur (VC, SLVC), servocommande avec codeur',
                        'V / f (lineare, quadratica, FCC, ECO), regolazione vettoriale con e senza encoder (VC, SLVC), servoregolazione con encoder',
                        'V / f (lineární, kvadratický, FCC, ECO), vektorová regulace s a bez snímače (VC, SLVC), servoregulace se snímačem',
                        'V / f (lineair, kwadratisch, FCC, ECO), vectorregeling met en zonder encoder (VC, SLVC), servoregeling met encoder',
                        'V / f (liniowe, kwadratowe, FCC, ECO), układ sterowania wektorowego z enkoderem i bez enkodera (VC, SLVC), sterowanie napędem serwo za pomocą enkodera',
                        'V / f (linear, lei do quadrado, FCC, ECO), controlo de vetores com e sem encoder (VC, SLVC), controlo servo com encoder',
                        'V / f (линейная, квадратичная зависимость, управление потокосцеплением FCC, экономичный ECO), векторное управление с энкодером и без него (VC, SLVC), сервоуправление с энкодером',
                        'V / f (doğrusal, karesel, FCC, ECO), enkoderli ve enkodersiz vektör kontrolü (VC, SLVC), enkoderli servo kontrolü',
                        'V / f（线性，平方律，FCC，ECO），带/不带编码器的矢量控制（VC、SLVC），带编码器的伺服控制'
                    ]
                },
                'format': {
                    'title': [
                        'Format',
                        'Bauform',
                        'Formato',
                        'Format',
                        'Formato',
                        'Formát',
                        'Formaat',
                        'Format',
                        'Formato',
                        'Формат',
                        'Biçim',
                        '格式'
                    ],
                    'builtInUnitCompact': [
                        'Built-in unit (compact)',
                        'Einbaugerät (compact)',
                        'Built-in unit (compact)',
                        'Built-in unit (compact)',
                        'Built-in unit (compact)',
                        'Built-in unit (compact)',
                        'Built-in unit (compact)',
                        'Built-in unit (compact)',
                        'Built-in unit (compact)',
                        'Built-in unit (compact)',
                        'Built-in unit (compact)',
                        'Built-in unit (compact)'
                    ],
                    'builtInUnitModularPowerModuleControlUnitCommissioningOptions': [
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options',
                        'Einbaugerät (modular)\nPower Module, Control Unit, commissioning options',
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options',
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options',
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options',
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options',
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options',
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options',
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options',
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options',
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options',
                        'Built-in unit (modular)\nPower Module, Control Unit, commissioning options'
                    ],
                    'builtInUnitBlocksizeModular': [
                        'Built-in unit Blocksize (modular)',
                        'Einbaugerät, Bauform Blocksize (modular)',
                        'Built-in unit Blocksize (modular)',
                        'Built-in unit Blocksize (modular)',
                        'Built-in unit Blocksize (modular)',
                        'Built-in unit Blocksize (modular)',
                        'Built-in unit Blocksize (modular)',
                        'Built-in unit Blocksize (modular)',
                        'Built-in unit Blocksize (modular)',
                        'Built-in unit Blocksize (modular)',
                        'Built-in unit Blocksize (modular)',
                        'Built-in unit Blocksize (modular)'
                    ],
                    'distributedCompactDevice': [
                        'Distributed compact device',
                        'Distributed compact device',
                        'Distributed compact device',
                        'Distributed compact device',
                        'Distributed compact device',
                        'Distributed compact device',
                        'Distributed compact device',
                        'Distributed compact device',
                        'Distributed compact device',
                        'Distributed compact device',
                        'Distributed compact device',
                        'Distributed compact device'
                    ],
                    'builtInUnitBlocksizeModularBuiltInUnitBooksizeModularBuiltInUnitChassisModularCabinetUnit': [
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit',
                        'Einbaugerät, Bauform Blocksize (modular)\nEinbaugerät, Bauform Booksize (modular)\nEinbaugerät, Bauform Chassis (modular)\nCabinet unit',
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit',
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit',
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit',
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit',
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit',
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit',
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit',
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit',
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit',
                        'Built-in unit blocksize (modular)\nBuilt-in unit booksize (modular)\nBuilt-in unit Chassis (modular)\nCabinet unit'
                    ],
                    'distributedCompactDeviceForMountingOnAMotor': [
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor',
                        'Distributed compact device for mounting on a motor'
                    ],
                    'builtInUnit': [
                        'Built-in unit',
                        'Einbaugerät',
                        'Unidad integrada',
                        'Unité intégrée',
                        'Unità integrata',
                        'Zabudovaná jednotka',
                        'Ingebouwde eenheid',
                        'Moduł do wbudowania',
                        'Unidade incorporada',
                        'Встроенный блок',
                        'Dahili ünite',
                        '内置单元'
                    ],
                    'builtInUnitBlockSize': [
                        'Built-in unit blocksize',
                        'Einbaugerät, Bauform Blocksize',
                        'Unidad integrada Blocksize',
                        'Unité intégrée Blocksize',
                        'Unità integrata blocksize',
                        'Zabudovaná jednotka Blocksize',
                        'Ingebouwde eenheid, blokgrootte',
                        'Moduł do wbudowania blocksize',
                        'Unidade blocksize incorporada',
                        'Встроенный блок формата Blocksize',
                        'Dahili ünite blok boyut',
                        '内置块型单元'
                    ],
                    'builtInUnitBookSize': [
                        'Built-in unit booksize',
                        'Einbaugerät, Bauform Booksize',
                        'Unidad integrada Booksize',
                        'Unité intégrée Booksize',
                        'Unità integrata blocksize',
                        'Zabudovaná jednotka Booksize',
                        'Ingebouwde eenheid, boekgrootte',
                        'Moduł do wbudowania booksize',
                        'Unidade blocksize incorporada',
                        'Встроенный блок формата Booksize',
                        'Dahili ünite kitap boyut',
                        '内置书本型单元'
                    ],
                    'builtInUnitChassis': [
                        'Built-in unit chassis',
                        'Einbaugerät, Bauform Chassis',
                        'Unidad integrada Chassis',
                        'Unité intégrée Chassis',
                        'Unità integrata chassis',
                        'Zabudovaná jednotka Chassis',
                        'Ingebouwde eenheid, chassis',
                        'Moduł do wbudowania chassis',
                        'Unidade de chassis incorporada',
                        'Встроенный блок формата Chassis',
                        'Dahili ünite ana gövde',
                        '内置装机装柜型单元'
                    ],
                    'cabinetUnit': [
                        'Cabinet unit',
                        'Schrankgerät',
                        'Unidad de armario',
                        'Armoire',
                        'Unità in armadio',
                        'Skříňová jednotka',
                        'Kasteenheid',
                        'Moduł do montażu w szafie',
                        'Unidade de armário',
                        'Шкаф',
                        'Pano ünitesi',
                        '变频柜单元'
                    ],
                    'compactDevice': [
                        'Compact device',
                        'Kompaktgerät',
                        'Dispositivo compacto',
                        'Appareil compact',
                        'Apparecchio compatto',
                        'Kompaktní zařízení',
                        'Compact apparaat',
                        'Urządzenie kompaktowe',
                        'Dispositivo compacto',
                        'Компактное устройство',
                        'Kompakt cihaz',
                        '紧凑型设备'
                    ],
                    'compactDeviceForMountingOnAMotor': [
                        'Compact device for mounting on a motor',
                        'Kompaktgerät zur Montage auf einem Motor',
                        'Dispositivo compacto para montaje en un motor',
                        'Appareil compact pour montage sur moteur',
                        'Apparecchio compatto per il montaggio su un motore',
                        'Kompaktní zařízení pro montáž na motor',
                        'Compact apparaat voor montage op een motor',
                        'Urządzenie kompaktowe do montażu na silniku',
                        'Dispositivo compacto para montagem num motor',
                        'Компактное устройство для монтажа на электродвигатель',
                        'Motora montaj için kompakt cihaz',
                        '用于在电机上安装的紧凑型设备'
                    ],
                    'wallMountedUnit': [
                        'Wall mounted unit',
                        'Wandmontagegerät',
                        'Unidad de montaje en pared',
                        'Unité à montage mural',
                        'Unità montata a parete',
                        'Jednotka namontovaná na stěně',
                        'Wandgemonteerde eenheid',
                        'Moduł do montażu naściennego',
                        'Unidade de montagem na parede',
                        'Настенный блок',
                        'Duvar tipi ünite',
                        '穿墙式安装单元'
                    ]
                },
                'safetyFunctions': [
                    'Safety functions',
                    'Sicherheitsfunktionen',
                    'Funciones de seguridad',
                    'Safety functions',
                    'Safety Functions',
                    'Bezpečnostní funkce',
                    'Veiligheidsfuncties',
                    'Funkcje bezpieczeństwa',
                    'Funções de segurança',
                    'Функции обеспечения безопасности',
                    'Güvenlik Fonksiyonları',
                    '安全功能'
                ],
                'supplyVoltage': [
                    'Supply Voltage',
                    'Versorgungsspannung',
                    'Tensión de alimentación',
                    'Tension d\'alimentation',
                    'Tensione di alimentazione',
                    'Napájecí napětí',
                    'Voedingsspanning',
                    'Napięcie zasilania',
                    'Tensão de alimentação',
                    'Напряжение питания',
                    'Besleme Gerilimi',
                    '供电电压'
                ],
                'generatorOperation': {
                    'title': [
                        'Generator operation',
                        'Generatorischer Betrieb',
                        'Régimen generador',
                        'Fonctionnement en génératrice',
                        'Funzionamento come generatore',
                        'Obsluha generátoru',
                        'Generatorwerking',
                        'Praca generatora',
                        'Operação do gerador',
                        'Работа в режиме генератора',
                        'Jeneratör çalışması',
                        '发电机运行'
                    ],
                    'energyRecoveryIntoTheLineSupply': [
                        'Energy recovery into the line supply',
                        'Netzrückspeisung',
                        'Recuperación de energía y realimentación a la red',
                        'Réinjection d\'énergie dans le réseau',
                        'Recupero di energia in rete',
                        'Rekuperace energie do napájecí sítě',
                        'Energieterugwinning naar lijnvoeding',
                        'Zwrot energii do sieci zasilające',
                        'Recuperação de energia na alimentação da linha',
                        'Рекуперация энергии в сети питания',
                        'Hat beslemesinde enerji geri kazanımı',
                        '能量回馈至主电源'
                    ],
                    'optionalBrakingResistor': [
                        'Optional braking resistor',
                        'Optionaler Bremswiderstand',
                        'Resistencia de frenado opcional',
                        'Résistance de freinage en option',
                        'Resistenza di frenatura opzionale',
                        'Brzdný rezistor (volitelný doplněk)',
                        'Optionele remweerstand',
                        'Opcjonalny rezystor hamowania',
                        'Resistência de travagem opcional',
                        'Дополнительный тормозной резистор',
                        'Opsiyonel frenleme direnci',
                        '可选制动电阻'
                    ],
                    'optionalBrakingResistorEnergyRecovery': [
                        'Optional braking resistor, energy recovery',
                        'Optionaler Bremswiderstand, Energierückspeisung',
                        'Resistencia de frenado opcional, recuperación de energía',
                        'Résistance de freinage en option, réinjection d\'énergie',
                        'Resistenza di frenatura opzionale, recupero di energia',
                        'Brzdný rezistor (volitelný doplněk), rekuperace energie',
                        'Optionele remweerstand, energieterugwinning',
                        'Opcjonalny rezystor hamowania, zwrot energii',
                        'Resistência de travagem opcional, recuperação de energia',
                        'Дополнительный тормозной резистор, рекуперация энергии',
                        'Opsiyonel frenleme direnci, enerji geri kazanımı',
                        '可选制动电阻，能量回馈'
                    ]
                },
                'motorType': {
                    'title': [
                        'Motor type',
                        'Motortyp',
                        'Tipo de motor',
                        'Type de moteur',
                        'Tipo di motore',
                        'Typ motoru',
                        'Motortype',
                        'Typ silnika',
                        'Tipo de motor',
                        'Тип электродвигателя',
                        'Motor tipi',
                        '电机类型'
                    ],
                    'simoticsGpsd': [
                        'SIMOTICS GP / SD',
                        'SIMOTICS GP / SD',
                        'SIMOTICS GP/SD',
                        'SIMOTICS GP / SD',
                        'SIMOTICS GP / SD',
                        'SIMOTICS GP / SD',
                        'SIMOTICS GP / SD',
                        'SIMOTICS GP / SD',
                        'SIMOTICS GP / SD',
                        'SIMOTICS GP/SD',
                        'SIMOTICS GP / SD',
                        'SIMOTICS GP / SD'
                    ],
                    'simoticsTn': [
                        'SIMOTICS TN',
                        'SIMOTICS TN',
                        'SIMOTICS TN',
                        'SIMOTICS TN',
                        'SIMOTICS TN',
                        'SIMOTICS TN',
                        'SIMOTICS TN',
                        'SIMOTICS TN',
                        'SIMOTICS TN',
                        'SIMOTICS TN',
                        'SIMOTICS TN',
                        'SIMOTICS TN'
                    ],
                    'simoticsFd': [
                        'SIMOTICS FD',
                        'SIMOTICS FD',
                        'SIMOTICS FD',
                        'SIMOTICS FD',
                        'SIMOTICS FD',
                        'SIMOTICS FD',
                        'SIMOTICS FD',
                        'SIMOTICS FD',
                        'SIMOTICS FD',
                        'SIMOTICS FD',
                        'SIMOTICS FD',
                        'SIMOTICS FD'
                    ],
                    'simoticsHt': [
                        'SIMOTICS HT',
                        'SIMOTICS HT',
                        'SIMOTICS HT',
                        'SIMOTICS HT',
                        'SIMOTICS HT',
                        'SIMOTICS HT',
                        'SIMOTICS HT',
                        'SIMOTICS HT',
                        'SIMOTICS HT',
                        'SIMOTICS HT',
                        'SIMOTICS HT',
                        'SIMOTICS HT'
                    ],
                    'simoticsXp': [
                        'SIMOTICS XP',
                        'SIMOTICS XP',
                        'SIMOTICS XP',
                        'SIMOTICS XP',
                        'SIMOTICS XP',
                        'SIMOTICS XP',
                        'SIMOTICS XP',
                        'SIMOTICS XP',
                        'SIMOTICS XP',
                        'SIMOTICS XP',
                        'SIMOTICS XP',
                        'SIMOTICS XP'
                    ],
                    'simogear': [
                        'SIMOGEAR',
                        'SIMOGEAR',
                        'SIMOGEAR',
                        'SIMOGEAR',
                        'SIMOGEAR',
                        'SIMOGEAR',
                        'SIMOGEAR',
                        'SIMOGEAR',
                        'SIMOGEAR',
                        'SIMOGEAR',
                        'SIMOGEAR',
                        'SIMOGEAR'
                    ],
                    'simoticsDp': [
                        'SIMOTICS DP',
                        'SIMOTICS DP',
                        'SIMOTICS DP',
                        'SIMOTICS DP',
                        'SIMOTICS DP',
                        'SIMOTICS DP',
                        'SIMOTICS DP',
                        'SIMOTICS DP',
                        'SIMOTICS DP',
                        'SIMOTICS DP',
                        'SIMOTICS DP',
                        'SIMOTICS DP'
                    ],
                    'simoticsM1ph8': [
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8',
                        'SIMOTICS M-1PH8'
                    ],
                    'simoticsS': [
                        'SIMOTICS S',
                        'SIMOTICS S',
                        'SIMOTICS S',
                        'SIMOTICS S',
                        'SIMOTICS S',
                        'SIMOTICS S',
                        'SIMOTICS S',
                        'SIMOTICS S',
                        'SIMOTICS S',
                        'SIMOTICS S',
                        'SIMOTICS S',
                        'SIMOTICS S'
                    ],
                    'simoticsM': [
                        'SIMOTICS M',
                        'SIMOTICS M',
                        'SIMOTICS M',
                        'SIMOTICS M',
                        'SIMOTICS M',
                        'SIMOTICS M',
                        'SIMOTICS M',
                        'SIMOTICS M',
                        'SIMOTICS M',
                        'SIMOTICS M',
                        'SIMOTICS M',
                        'SIMOTICS M'
                    ],
                    'simoticsL': [
                        'SIMOTICS L',
                        'SIMOTICS L',
                        'SIMOTICS L',
                        'SIMOTICS L',
                        'SIMOTICS L',
                        'SIMOTICS L',
                        'SIMOTICS L',
                        'SIMOTICS L',
                        'SIMOTICS L',
                        'SIMOTICS L',
                        'SIMOTICS L',
                        'SIMOTICS L'
                    ],
                    'simoticsT': [
                        'SIMOTICS T',
                        'SIMOTICS T',
                        'SIMOTICS T',
                        'SIMOTICS T',
                        'SIMOTICS T',
                        'SIMOTICS T',
                        'SIMOTICS T',
                        'SIMOTICS T',
                        'SIMOTICS T',
                        'SIMOTICS T',
                        'SIMOTICS T',
                        'SIMOTICS T'
                    ],
                    'simoticsS1fl6': [
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6',
                        'SIMOTICS S-1FL6'
                    ],
                    'simoticsS1fk2': [
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2',
                        'SIMOTICS S-1FK2'
                    ]
                },
                'motorTypeTooltips': {
                    'simoticsGpsd': [
                        'standard induction motors aluminum / cast iron',
                        'Norm-Asynchronmotoren aus Aluminium / Gusseisen',
                        'motores de inducción estándar de alumino/fundición gris',
                        'moteurs asynchrones standard en aluminium / fonte',
                        'motori a induzione standard in alluminio / ghisa',
                        'standardní indukční motory, hliník/litina',
                        'standaard inductiemotoren aluminium / gietijzer',
                        'standardowe silniki indukcyjne aluminium / żeliwo',
                        'motores de indução padrão alumínio / ferro fundido',
                        'стандартные асинхронные двигатели из алюминия/чугуна',
                        'standart alüminyum / döküm demir indüksiyon motorları',
                        '标准感应电机，铝/铸铁'
                    ],
                    'simoticsTn': [
                        'trans-standard motors',
                        'Transnormmotoren',
                        'motores transnormalizados',
                        'moteurs hors standard',
                        'motori Trans-Norm',
                        'velké motory',
                        'trans-standaard motoren',
                        'silniki trans-norm',
                        'Motores transnormalizados',
                        'электродвигатели transnorm',
                        'standart transmisyon motorları',
                        '跨标准电机'
                    ],
                    'simoticsFd': [
                        'compact induction motor with different cooling concepts',
                        'Kompakt-Asynchronmotor mit unterschiedlichen Kühlkonzepten',
                        'motor de inducción compacto con diferentes soluciones de refrigeración',
                        'moteur asynchrone compact avec différents concepts de refroidissement',
                        'motore compatto a induzione con sistemi di raffreddamento diversi',
                        'kompaktní indukční motor s jinou koncepcí chlazení',
                        'compacte inductiemotor met diverse koelingsconcepten',
                        'kompaktowy silnik indukcyjny z różnymi rozwiązaniami chłodzenia',
                        'motor de indução compacto com diferentes conceitos de refrigeração',
                        'компактный асинхронный двигатель с различными типами охлаждения',
                        'farklı soğutma konseptlerine sahip kompakt indüksiyon motoru',
                        '紧凑型感应电机，配置不同的冷却方案'
                    ],
                    'simoticsHt': [
                        'low-speed permanent magnet synchronous motors',
                        'Permanenterregte Synchronmotoren mit niedriger Drehzahl',
                        'motores síncronos de baja velocidad de imán permanente',
                        'moteurs synchrones à aimants permanents à basse vitesse',
                        'motori sincroni con magnete permanente a basso numero di giri',
                        'pomaluběžné synchronní motory s permanentními magnety',
                        'synchrone lagesnelheidsmotoren met permanente magneet',
                        'synchroniczne silniki wolnoobrotowe ze stałym magnesem',
                        'motores síncronos de baixa velocidade de ímanes permanentes',
                        'синхронные двигатели с постоянными магнитами и низкой частотой вращения',
                        'düşük hızlı kalıcı mıknatıslı senkron motorlar',
                        '低速永磁同步电机'
                    ],
                    'simoticsXp': [
                        'explosion-protected motors',
                        'Explosionsgeschützte Motoren',
                        'motores con protección contra explosiones',
                        'moteurs antidéflagrants',
                        'motori a prova di esplosione',
                        'motory v nevýbušném provedení',
                        'explosieveilige motoren',
                        'silniki do pracy w strefach zagrożonych wybuchem',
                        'motores antideflagrantes',
                        'взрывозащищенные двигатели',
                        'patlamaya karşı korumalı motorlar',
                        '防爆电机'
                    ],
                    'simogear': [
                        'geared motors',
                        'Getriebemotoren',
                        'motorreductores',
                        'motoréducteurs',
                        'motoriduttori',
                        'motory s převodovkou',
                        'motorreductoren',
                        'motoreduktory',
                        'motorredutores',
                        'редукторные двигатели',
                        'dişli motorlar',
                        '减速电机'
                    ],
                    'simoticsDp': [
                        'smoke extraction motors',
                        'Brandgasmotoren',
                        'motores para extracción de humos',
                        'moteurs de désenfumage',
                        'motori per estrazioni fumi',
                        'motory spalinových ventilátorů',
                        'motoren voor rookextractie',
                        'silniki do odprowadzania dymu',
                        'motores de extração de fumo',
                        'двигатели для систем дымоудаления',
                        'duman tahliye motorları',
                        '排烟电机'
                    ],
                    'simoticsM1ph8': [
                        'compact induction motors',
                        'Kompakt-Asynchronmotoren',
                        'motores de inducción compactos',
                        'moteurs asynchrones compacts',
                        'motore compatto a induzione',
                        'kompaktní indukční motory',
                        'compacte inductiemotoren',
                        'kompaktowe silniki indukcyjne',
                        'motores compactos de indução',
                        'компактные асинхронные двигатели',
                        'kompakt indüksiyon motorları',
                        '紧凑型感应电机'
                    ],
                    'simoticsS': [
                        'servomotors',
                        'Servomotoren',
                        'servomotores',
                        'servomoteurs',
                        'servomotori',
                        'servomotory',
                        'servomotoren',
                        'silniki serwo',
                        'servomotores',
                        'сервоприводы',
                        'servo motorlar',
                        '伺服电机'
                    ],
                    'simoticsM': [
                        'compact induction motor',
                        'Kompakt-Asynchronmotor',
                        'motor de inducción compacto',
                        'moteur asynchrone compact',
                        'motore compatto a induzione',
                        'kompaktní indukční motor',
                        'compacte inductiemotor',
                        'kompaktowy silnik indukcyjny',
                        'motor compacto de indução',
                        'компактный асинхронный двигатель',
                        'kompakt indüksiyon motoru',
                        '紧凑型感应电机'
                    ],
                    'simoticsL': [
                        'linear motors',
                        'Linearmotoren',
                        'motores lineales',
                        'moteurs linéaires',
                        'motori lineari',
                        'lineární motory',
                        'lineaire motoren',
                        'silniki liniowe',
                        'motores lineares',
                        'линейные двигатели',
                        'doğrusal motorlar',
                        '直线电机'
                    ],
                    'simoticsT': [
                        'torque motors',
                        'Torquemotoren',
                        'motores torque',
                        'moteurs couples',
                        'motori torque',
                        'momentové motory',
                        'koppelmotoren',
                        'silniki momentowe',
                        'motores de torque',
                        'моментные двигатели',
                        'tork motorları',
                        '力矩电机'
                    ],
                    'simoticsS1fk2': [
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system'
                    ],
                    'simoticsS1fl6': [
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system',
                        'servo drive system'
                    ]
                },
                'more': [
                    'more',
                    'mehr',
                    'más',
                    'plus',
                    'più',
                    'více',
                    'meer',
                    'więcej',
                    'mais',
                    'развернуть',
                    'daha fazla',
                    '展开'
                ],
                'less': [
                    'less',
                    'weniger',
                    'menos',
                    'moins',
                    'meno',
                    'méně',
                    'minder',
                    'mniej',
                    'menos',
                    'свернуть',
                    'daha az',
                    '收起'
                ],
                'configure': {
                    'title': [
                        'Configure',
                        'Konfigurieren',
                        'Configurar',
                        'Configuration',
                        'Configura',
                        'Konfigurovat',
                        'Configureren',
                        'Konfiguracja',
                        'Configurar',
                        'Конфигурировать',
                        'Yapılandırma',
                        '配置'
                    ],
                    'S120': [
                        'SINAMICS S120',
                        'SINAMICS S120',
                        'SINAMICS S120',
                        'SINAMICS S120',
                        'SINAMICS S120',
                        'SINAMICS S120',
                        'SINAMICS S120',
                        'SINAMICS S120',
                        'SINAMICS S120',
                        'SINAMICS S120',
                        'SINAMICS S120',
                        'SINAMICS S120'
                    ],
                    'S120_MC': [
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC',
                        'SINAMICS S120 AC/AC'
                    ],
                    'SYS_G115D': [
                        'SINAMICS G115D drive system',
                        'SINAMICS G115D Antriebssystem',
                        'SINAMICS G115D drive system',
                        'SINAMICS G115D drive system',
                        'SINAMICS G115D drive system',
                        'SINAMICS G115D drive system',
                        'SINAMICS G115D drive system',
                        'SINAMICS G115D drive system',
                        'SINAMICS G115D drive system',
                        'SINAMICS G115D drive system',
                        'SINAMICS G115D drive system',
                        'SINAMICS G115D drive system'
                    ],
                    'G115D': [
                        'SINAMICS G115D wall mounted',
                        'SINAMICS G115D wandmontiert',
                        'SINAMICS G115D wall mounted',
                        'SINAMICS G115D wall mounted',
                        'SINAMICS G115D wall mounted',
                        'SINAMICS G115D wall mounted',
                        'SINAMICS G115D wall mounted',
                        'SINAMICS G115D wall mounted',
                        'SINAMICS G115D wall mounted',
                        'SINAMICS G115D wall mounted',
                        'SINAMICS G115D wall mounted',
                        'SINAMICS G115D wall mounted'
                    ]
                },
                'accessorySelected': [
                    'Accessory selected',
                    'Zubehör ausgewählt',
                    'Accessory selected',
                    'Accessory selected',
                    'Accessory selected',
                    'Accessory selected',
                    'Accessory selected',
                    'Accessory selected',
                    'Accessory selected',
                    'Accessory selected',
                    'Accessory selected',
                    'Accessory selected'
                ],
                'accessoriesSelected': [
                    'Accessories selected',
                    'Zubehör ausgewählt ...',
                    'Accessories selected',
                    'Accessories selected',
                    'Accessories selected',
                    'Accessories selected',
                    'Accessories selected',
                    'Accessories selected',
                    'Accessories selected',
                    'Accessories selected',
                    'Accessories selected',
                    'Accessories selected'
                ],
                'addOptions': [
                    'Add options ...',
                    'Optionen hinzufügen ...',
                    'agregar opciones …',
                    'ajouter des options …',
                    'Aggiungere opzioni ...',
                    'Přidat atribut ...',
                    'Opties toevoegen ...',
                    'Dodaj opcje ...',
                    'Adicionar opcionais ...',
                    'добавить опции ...',
                    'seçenekleri ekle ...',
                    '添加可选项 ...'
                ],
                'top10Options': [
                    'Top 10 options',
                    'Top 10 Optionen',
                    'Top 10 options',
                    'Top 10 options',
                    'Top 10 options',
                    'Top 10 options',
                    'Top 10 options',
                    'Top 10 options',
                    'Top 10 options',
                    'Top 10 options',
                    'Top 10 options',
                    'Top 10 options'
                ],
                'options': {
                    'B02': [
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204',
                        'Acceptance test certificate 3.1 in accordance with EN 10204'
                    ],
                    'F01': [
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)',
                        'Mounting of holding brake (standard assignment)'
                    ],
                    'F10': [
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC',
                        'Brake supply voltage 24 V DC'
                    ],
                    'F11': [
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 230 V AC, 50/60 Hz (ordered with F01)'
                    ],
                    'F12': [
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)',
                        'Brake supply voltage 400 V AC, 50/60 Hz (ordered with F01)'
                    ],
                    'F70': [
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan',
                        'Mounting of separately driven fan'
                    ],
                    'G11': [
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 HTL, 1024 I rotary pulse encoder'
                    ],
                    'G12': [
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder',
                        'Mounting of Kübler Sendix 5020 TTL, 1024 I rotary pulse encoder'
                    ],
                    'G04': [
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder',
                        'Mounting of LL 861 900 220 rotary pulse encoder'
                    ],
                    'G40': [
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only',
                        'Prepared for mounted components, centering hole only'
                    ]
                },
                'supplyVoltagePower': [
                    'Supply voltage/power',
                    'Anschlussspannung/ Leistung',
                    'Supply voltage/power',
                    'Supply voltage/power',
                    'Supply voltage/power',
                    'Supply voltage/power',
                    'Supply voltage/power',
                    'Supply voltage/power',
                    'Supply voltage/power',
                    'Supply voltage/power',
                    'Supply voltage/power',
                    'Supply voltage/power'
                ],
                'motors': {
                    'G120C': {
                        '0,55-18,5kw': [
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU'
                        ],
                        '0,75-25hp': [
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU'
                        ],
                        '0,55-132kw': [
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP',
                            '0,55 - 18,5 kW for PROFIBUS DP, USS/Modbus RTU<br>0,55 - 132 kW for PROFINET, EtherNet/IP'
                        ],
                        '0,75-150hp': [
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP',
                            '0,75 - 25 hp for PROFIBUS DP, USS/Modbus RTU<br>0,75 - 150 hp for PROFINET, EtherNet/IP'
                        ]
                    },
                    'G120P': {
                        '0,37-560kw': [
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, eingebaute Geräte IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54',
                            '0,37 - 560 kW, built-in units IP20<br>0,37 - 90 kW, wall mounted units IP55<br>75 - 560 kW, cabinet units IP20 up to IP54'
                        ],
                        '0,5-700hp': [
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, eingebaute Geräte IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54',
                            '0,5 - 700 hp, built-in units IP20<br>0,5 - 100 hp, wall mounted units IP55<br>75 - 700 hp, cabinet units IP20 up to IP54'
                        ],
                        '11-630kw': [
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, eingebaute Geräte IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54',
                            '11 - 630 kW, built-in units IP20<br>315 - 630 kW, cabinet units IP20 up to IP54'
                        ],
                        '10-700hp': [
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54',
                            '350 - 700 hp, cabinet units IP20 up to IP54'
                        ]
                    },
                    'G130': {
                        '110-900kw': [
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, eingebaute Geräte IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54',
                            '110 - 560 kW, built-in units IP20<br>110 - 900 kW, cabinet units IP20 up to IP54'
                        ],
                        '150-1250hp': [
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, eingebaute Geräte IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 1250 hp, cabinet units IP20 up to IP54'
                        ],
                        '75-2700kw': [
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, eingebaute Geräte IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54',
                            '75 - 800 kW, built-in units IP20<br>75 - 2700 kW, cabinet units IP20 up to IP54'
                        ],
                        '150-2700hp': [
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, eingebaute Geräte IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54',
                            '150 - 800 hp, built-in units IP20<br>150 - 2700 hp, cabinet units IP20 up to IP54'
                        ]
                    },
                    'G180': {
                        'sector-specific': {
                            '2,2-630kw': [
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, einbaugerät IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54',
                                '2,2 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 630 kW, cabinet unit IP20 up to IP54'
                            ],
                            '3-1100hp': [
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, einbaugerät IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54',
                                '2 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 987 hp, cabinet unit IP20 up to IP54'
                            ],
                            '7,5-200kw': [
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, einbaugerät IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54',
                                '7,5 - 200 kW, built-in unit IP20 (optional IP21)<br>250 - 5800 kW, cabinet unit IP20 up to IP54'
                            ],
                            '7,5-200hp': [
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, einbaugerät IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54',
                                '7,5 - 200 hp, built-in unit IP20 (optional IP21)<br>250 - 6482 hp, cabinet unit IP20 up to IP54'
                            ]
                        }
                    },
                    'S120': {
                        'high': {
                            '0,55-4kw': [
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, einbaugerät, bauform blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20'
                            ],
                            '0,75-5hp': [
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, einbaugerät, bauform blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20'
                            ],
                            '0,55-55kw': [
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, einbaugerät, bauform blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20'
                            ],
                            '0,75-60hp': [
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, einbaugerät, bauform blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20'
                            ],
                            '0,55-3040kw': [
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, einbaugerät, bauform blocksize IP20<br>1,6 - 107 kW, einbaugerät, bauform booksize IP00 / IP20<br>110 - 3040 KW, einbaugerät, bauform chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 3040 KW, built-in unit chassis IP00 / IP20<br>4,8 - 3040 kW, cabinet units IP20, IP21, IP23, IP43, IP54'
                            ],
                            '0,75-4370hp': [
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, einbaugerät, bauform blocksize IP20<br>1,5 - 150 hp, einbaugerät, bauform booksize IP00 / IP20<br>150 - 4370 hp, einbaugerät, bauform chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 4370 hp, built-in unit chassis IP00 / IP20<br>5 - 4370 hp, cabinet units IP20, IP21, IP23, IP43, IP54'
                            ],
                            '75-6840kw': [
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, einbaugerät, bauform blocksize IP20<br>75 - 5700 KW, einbaugerät, bauform chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54',
                                '11 - 250 kW, built-in unit blocksize IP20<br>75 - 5700 KW, built-in unit chassis IP00 / IP20<br>75 - 5700 kW, cabinet units IP20, IP21, IP23, IP43, IP54'
                            ],
                            '75-5600hp': [
                                '10 - 400 hp, built-in unit blocksize IP20',
                                '10 - 400 hp, einbaugerät, bauform blocksize IP20',
                                '10 - 400 hp, built-in unit blocksize IP20',
                                '10 - 400 hp, built-in unit blocksize IP20',
                                '10 - 400 hp, built-in unit blocksize IP20',
                                '10 - 400 hp, built-in unit blocksize IP20',
                                '10 - 400 hp, built-in unit blocksize IP20',
                                '10 - 400 hp, built-in unit blocksize IP20',
                                '10 - 400 hp, built-in unit blocksize IP20',
                                '10 - 400 hp, built-in unit blocksize IP20',
                                '10 - 400 hp, built-in unit blocksize IP20',
                                '10 - 400 hp, built-in unit blocksize IP20'
                            ]
                        },
                        'servo': {
                            '0,55-4kw': [
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, einbaugerät, bauform blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20',
                                '0,55 - 4 kW, built-in unit blocksize IP20'
                            ],
                            '0,75-5hp': [
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, einbaugerät, bauform blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20',
                                '0,75 - 5 hp, built-in unit blocksize IP20'
                            ],
                            '0,55-55kw': [
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, einbaugerät, bauform blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20',
                                '0,55 - 55 kW, built-in unit blocksize IP20'
                            ],
                            '0,75-60hp': [
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, einbaugerät, bauform blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20',
                                '0,75 - 60 hp, built-in unit blocksize IP20'
                            ],
                            '0,55-800kw': [
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20',
                                '0,55 - 250 kW, einbaugerät, bauform blocksize IP20<br>1,6 - 107 kW, einbaugerät, bauform booksize IP00 / IP20<br>110 - 800 KW, einbaugerät, bauform chassis IP00 / IP20',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20',
                                '0,55 - 250 kW, built-in unit blocksize IP20<br>1,6 - 107 kW, built-in unit booksize IP00 / IP20<br>110 - 800 KW, built-in unit chassis IP00 / IP20'
                            ],
                            '0,75-1150hp': [
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20',
                                '0,75 - 400 hp, einbaugerät, bauform blocksize  IP20<br>1,5 - 150 hp, einbaugerät, bauform booksize IP00 / IP20<br>150 - 1150 hp, einbaugerät, bauform chassis IP00 / IP20',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20',
                                '0,75 - 400 hp, built-in unit blocksize IP20<br>1,5 - 150 hp, built-in unit booksize IP00 / IP20<br>150 - 1150 hp, built-in unit chassis IP00 / IP20'
                            ],
                            '75-1250hp': [
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20',
                                '0,75 - 400 hp, Built-in unit blocksize IP20'
                            ]
                        }
                    },
                    'G115D': {
                        '0,5-10hp': [
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted',
                            '0,5 - 10 hp Wall mounted'
                        ]
                    }
                }
            },
            'application': {
                'title': [
                    'Application',
                    'Anwendung',
                    'Aplicación',
                    'Application',
                    'Applicazione',
                    'Aplikace',
                    'Toepassing',
                    'Aplikację',
                    'Aplicação',
                    'Приложение',
                    'Uygulama',
                    '应用'
                ],
                'Machining': [
                    'Machining',
                    'Bearbeiten',
                    'Mecanizado',
                    'Usinage',
                    'Lavorazione',
                    'Obrábění',
                    'Bewerking',
                    'Obróbka skrawaniem',
                    'Operar',
                    'Механическая обработка',
                    'Makine ile işleme',
                    '加工'
                ],
                'Moving': [
                    'Moving',
                    'Bewegen',
                    'Desplazamiento',
                    'Mouvement',
                    'Movimentazione',
                    'Přesouvání',
                    'Bewegen',
                    'Przesunięcie',
                    'Mover',
                    'Перемещение',
                    'Taşıma',
                    '移动'
                ],
                'Positioning': [
                    'Positioning',
                    'Positionieren',
                    'Posicionamiento',
                    'Positionnement',
                    'Posizionamento',
                    'Nastavení polohy',
                    'Positioneren',
                    'Pozycjonowanie',
                    'Posicionar',
                    'Позиционирование',
                    'Pozisyonlandırma',
                    '定位'
                ],
                'Processing': [
                    'Processing',
                    'Verarbeiten',
                    'Procesamiento',
                    'Transformation',
                    'Trasformazione',
                    'Zpracování',
                    'Verwerking',
                    'Przetwarzanie',
                    'Processar',
                    'Обработка',
                    'İşleme',
                    '处理'
                ],
                'Pumping': [
                    'Pumping/ventilating/compressing',
                    'Pumpen/Lüften/Verdichten',
                    'Pumping/ventilating/compressing',
                    'Pumping/ventilating/compressing',
                    'Pumping/ventilating/compressing',
                    'Pumping/ventilating/compressing',
                    'Pumping/ventilating/compressing',
                    'Pumping/ventilating/compressing',
                    'Pumping/ventilating/compressing',
                    'Pumping/ventilating/compressing',
                    'Pumping/ventilating/compressing',
                    '抽水'
                ]
            },
            'category': {
                'standardPerformanceConverter': [
                    'Standard Performance converter',
                    'Standard Performance Frequenzumrichter',
                    'Convertidor Standard Performance',
                    'Variateurs Standard Performance',
                    'Convertitore Standard Performance',
                    'Standardní výkonový měnič',
                    'Standard Performance-omvormer',
                    'Standardowy przekształtnik typu Performance',
                    'Conversor de desempenho padrão',
                    'Стандартный преобразователь',
                    'Standart Performanslı konvertör',
                    '标准性能变频器'
                ],
                'sectorSpecificConverter': [
                    'Industry-Specific converter',
                    'Branchenspezifische Umrichter',
                    'Convertidores industriales',
                    'Variateurs spécifiques au secteur d\'activité',
                    'Convertitori specifici per il settore industriale',
                    'Měniče pro specifické průmyslové oblasti',
                    'Industriespecifieke omvormers',
                    'Przekształtniki branżowe',
                    'Conversores específicos da indústria',
                    'Специализированные преобразователи',
                    'Sektöre Özel Konvertörler',
                    '行业专用变频器'
                ],
                'industrySpecificConverters': [
                    'Industry-Specific converter',
                    'Branchenspezifische Umrichter',
                    'Convertidores industriales',
                    'Variateurs spécifiques au secteur d\'activité',
                    'Convertitori specifici per il settore industriale',
                    'Měniče pro specifické průmyslové oblasti',
                    'Industriespecifieke omvormers',
                    'Przekształtniki branżowe',
                    'Conversores específicos da indústria',
                    'Специализированные преобразователи',
                    'Sektöre Özel Konvertörler',
                    '行业专用变频器'
                ],
                'distributedConverter': [
                    'Distributed converter',
                    'Dezentraler Umrichter',
                    'Convertidor distribuido',
                    'Variateurs décentralisés',
                    'Convertitore decentrato',
                    'Distribuovaný měnič',
                    'Gedistribueerde omvormer',
                    'Przekształtnik rozproszony',
                    'Conversor distribuído',
                    'Децентрализованный преобразователь',
                    'Dağıtımlı konvertör',
                    '分布式变频器'
                ],
                'servoConverter': [
                    'Servo converter',
                    'Servo-Umrichter',
                    'Servoconvertidor',
                    'Variateurs servo',
                    'Servoconvertitore',
                    'Měnič pro servopohony',
                    'Servo-omvormer',
                    'Przekształtnik serwo',
                    'Conversor de servo',
                    'Сервопреобразователь',
                    'Servo konvertör',
                    '伺服驱动器'
                ],
                'highPerformanceConverter': [
                    'High Performance converter',
                    'High Performance Umrichter',
                    'Convertidor High Performance',
                    'Variateurs High Performance',
                    'Convertitore High Performance',
                    'Měnič o vysokém výkonu',
                    'High Performance-omvormer',
                    'Przekształtnik typu High Performance',
                    'Conversor de Alto Desempenho',
                    'Высокодинамичный преобразователь',
                    'Yüksek Performanslı konvertör',
                    '高性能变频器'
                ]
            },
            'pleaseEnterMotorData': [
                'Please enter motor data',
                'Bitte geben Sie die Motordaten ein',
                'Por favor ingrese los datos del motor',
                'S\'il vous plaît entrer les données du moteur',
                'Si prega di inserire i dati del motore',
                'Zadejte údaje o motoru',
                'Voer motorgegevens in',
                'Wprowadź dane silnika',
                'Por favor insira os dados do motor',
                'Пожалуйста, введите данные двигателя',
                'Lütfen motor verilerini girin',
                '请输入电机数据'
            ],
            'noResults': [
                'No results',
                'Keine Ergebnisse',
                'No hay resultados',
                'Aucun résultat',
                'Nessun risultato',
                'Žádné výsledky',
                'Geen resultaten',
                'Brak wyników',
                'Nenhum resultado',
                'Нет результатов',
                'Sonuç yok',
                '没有结果'
            ],
            'filterActive': [
                'Filter active',
                'Filter aktiv',
                'Filtro activo',
                'Filtre actif',
                'Filtro attivo',
                'Filtr je aktivní',
                'Filter actief',
                'Filtr aktywny',
                'Filtro ativo',
                'Фильтр активен',
                'Aktif filtre',
                '过滤有效'
            ]
        },
        'error': {
            'lineValues': [
                'Cannot load line values!',
                'Cannot load line values!',
                'Cannot load line values!',
                'Cannot load line values!',
                'Cannot load line values!',
                'Cannot load line values!',
                'Cannot load line values!',
                'Cannot load line values!',
                'Cannot load line values!',
                'Cannot load line values!',
                'Cannot load line values!',
                'Cannot load line values!'
            ],
            'lineItems': [
                'Cannot load line items!',
                'Cannot load line items!',
                'Cannot load line items!',
                'Cannot load line items!',
                'Cannot load line items!',
                'Cannot load line items!',
                'Cannot load line items!',
                'Cannot load line items!',
                'Cannot load line items!',
                'Cannot load line items!',
                'Cannot load line items!',
                'Cannot load line items!'
            ]
        },
        'footer': {
            'openSiemensWebsite': [
                'Open Siemens Website',
                'Öffnen Sie die Siemens-Webseite',
                'Abrir sitio web de Siemens',
                'Ouvrir site web Siemens',
                'Apri sito web Siemens',
                'Otevřená internetová stránka firmy Siemens',
                'Siemens-website openen',
                'Przejdź do strony Siemens',
                'Abrir página da Internet da Siemens',
                'Открыть веб-сайт Siemens',
                'Siemens Web Sitesini Açın',
                '打开西门子网站'
            ],
            'getInTouch': [
                'Get in touch',
                'Kontaktieren Sie uns einfach',
                'Contáctenos',
                'Prendre contact',
                'Contattateci',
                'Zůstaňte ve spojení',
                'Contact opnemen',
                'Skontaktuj się z nami',
                'Entrar em contacto connosco',
                'Свяжитесь',
                'İletişime geçin',
                '联系我们'
            ],
            'globalChannels': [
                'Follow our global channels',
                'Folgen Sie unseren globalen Kanälen',
                'Síguenos en nuestras Redes Sociales',
                'Suivre nos chaînes mondiales',
                'Seguite i nostri canali internazionali',
                'Sledujte naše globální kanály',
                'Volg onze wereldwijde kanalen',
                'Śledź nasze kanały',
                'Siga os nossos canais globais',
                'Используйте наши глобальные каналы',
                'Global kanallarımızı takip edin',
                '关注我们的社交账号'
            ],
            'globalWebsite': [
                'siemens.com Global Website',
                'siemens.com Global Website',
                'Página web global siemens.com',
                'Site web mondial siemens.com',
                'Sito web internazionale siemens.com',
                'Globální internetová stránka siemens.com',
                'siemens.com internationale website',
                'siemens.com Global Website',
                'siemens.com Website Global',
                'Глобальный веб-сайт siemens.com',
                'siemens.com Global Web Sitesi',
                'siemens.com 全球网站'
            ],
            'siemensCorporateInfo': [
                'Siemens AG, 1996  2019 Corporate Information',
                'Siemens AG, 1996  2019 Corporate Information',
                'Siemens AG, 1996  2019 Información corporativa',
                'Siemens AG, 1996  2019 Corporate Information',
                'Siemens AG, 1996  2019 Informazioni aziendali',
                'Siemens AG, 1996 - 2019, korporátní informace',
                'Siemens AG, 1996 2019 corporate-informatie',
                'Siemens AG, 1996  2019 Corporate Information',
                'Siemens AG, 1996 2019 Informações da empresa',
                'Siemens AG, 1996  2019 Корпоративная информация',
                'Siemens AG, 1996  2019 Kurumsal Bilgiler',
                'Siemens AG, 1996  2019 公司信息'
            ],
            'corporateInfo': [
                'Corporate Information',
                'Unternehmensinformation',
                'Información corporativa',
                'Informations sur l\'entreprise',
                'Informazioni aziendali',
                'Korporátní informace',
                'Corporate-informatie',
                'Więcej informacji',
                'Informações da empresa',
                'Корпоративная информация',
                'Kurumsal Bilgiler',
                '公司信息'
            ],
            'privacyPolicy': [
                'Privacy Policy',
                'Datenschutzbestimmungen',
                'Política de Privacidad',
                'Politique de confidentialité',
                'Informativa sulla privacy',
                'Opatření pro ochranu soukromí',
                'Privacy-beleid',
                'Polityka prywatności',
                'Política de privacidade',
                'Политика конфиденциальности',
                'Gizlilik Politikası',
                '隐私策略'
            ],
            'cookiePolicy': [
                'Cookie Policy',
                'Cookie-Richtlinie',
                'Política de Cookies',
                'Politique de cookies',
                'Criteri relativi ai cookie',
                'Opatření týkající se cookie',
                'Cookie-beleid',
                'Polityka ciasteczek',
                'Política de cookies',
                'Политика файлов cookie',
                'Çerez politikası',
                'Cookie 策略'
            ],
            'termsOfUse': [
                'Terms of use',
                'Nutzungsbedingungen',
                'Términos de uso',
                'Conditions d\'utilisation',
                'Condizioni per l\'utilizzo',
                'Podmínky pro použití',
                'Gebruiksvoorwaarden',
                'Regulamin',
                'Termos de utilização',
                'Условия использования',
                'Kullanım Şartları',
                '使用条款'
            ],
            'contactUs': [
                'Contact us',
                'Kontakt'
            ],
            'contactUsLink': [
                'http://new.siemens.com/global/en/general/contact.html',
                'https://new.siemens.com/global/de/general/kontakt.html'
            ],
            'cookieNotice': [
                'Cookie Notice',
                'Cookie-Hinweise'
            ],
            'cookieNoticeLink': [
                'https://new.siemens.com/global/en/general/cookie-notice.html',
                'https://new.siemens.com/global/de/general/cookie-richtlinien.html'
            ],
            'corporateInformation': [
                'Corporate Information',
                'Impressum'
            ],
            'corporateInformationLink': [
                'https://new.siemens.com/global/en/general/legal.html',
                'https://new.siemens.com/global/de/general/legal.html'
            ],
            'digitalId': [
                'Digital ID',
                'Digitales Zertifikat'
            ],
            'digitalIdLink': [
                'https://new.siemens.com/global/en/general/digital-id.html',
                'https://new.siemens.com/global/de/general/digitales-zertifikat.html'
            ],
            'siemensWebsite': [
                'siemens.com Global Website',
                'siemens.com Germany'
            ],
            'siemensWebsiteLink': [
                'https://new.siemens.com/global/en.html',
                'https://new.siemens.com/global/de.html'
            ],
            'privacyNotice': [
                'Privacy Notice',
                'Datenschutz'
            ],
            'privacyNoticeLink': [
                'https://new.siemens.com/global/en/general/privacy-notice.html',
                'https://new.siemens.com/global/de/general/datenschutz.html'
            ],
            'siemens': [
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}',
                '© Siemens, 1996 - ${date}'
            ],
            'termsOfUseLink': [
                'https://new.siemens.com/global/en/general/terms-of-use.html',
                'https://new.siemens.com/global/de/general/nutzungsbedingungen.html'
            ]
        },
        'language': [
            'Language',
            'Sprache'
        ]
    };

    return translation;
};
