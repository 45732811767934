export const KEEP_ALIVE_URL = 'KEEPALIVEURL';
export const REGION = 'REGION';
export const RESULT_URL = 'RESULTURL';
export const CALLER = 'CALLER';
export const VERSION = 'VERSION';
export const COUNTRY = 'COUNTRY';
export const LANGUAGE = 'LANGUAGE';
export const HOOKURL = 'HOOKURL';
export const SCENARIO = 'SCENARIO';
export const CONFIGID = 'CONFIGID';
