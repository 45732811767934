import React, { ChangeEvent, CSSProperties, FocusEvent, KeyboardEvent, useRef } from 'react';

export interface InputProps {
    label: string;
    name: string;
    value: string;
    className?: string;
    dark?: boolean;
    disabled?: boolean;
    error?: boolean;
    placeholder?: string;
    readonly?: boolean;
    required?: boolean;
    style?: CSSProperties;
    getMessage?: (value: string) => string;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    onChange?: (value: string, name: string) => void;
}

const TextInput = ({ className, dark, disabled, error, label, name, placeholder, readonly, required, style, value, getMessage, onBlur, onChange }: InputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const message = getMessage ? getMessage(value) : '';

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => onChange && onChange(event.target.value, event.target.name);

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            inputRef.current?.blur();
        }
    };

    return (
        <div className={`text-input ${className ? className : ''} ${dark ? 'dark' : ''}`} style={style}>
            <div className='text-input-field'>
                <input ref={inputRef} className={error ? 'error' : ''} type='text' name={name} placeholder={placeholder} value={value} disabled={disabled} readOnly={readonly}
                    required={required} onBlur={onBlur} onChange={handleChange} onKeyPress={handleKeyPress} />
                <div className={`text-input-label ${placeholder || Boolean(value) ? 'filled' : ''}`}>
                    {label}
                </div>
            </div>
            {getMessage &&
                <div className={`text-input-message ${error ? 'error' : ''}`}>
                    {message ? message : <br />}
                </div>
            }
        </div>
    );
};

export default TextInput;
