import React, { ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';

interface TooltipProps {
    children: ReactNode;
    id: string;
    text: string;
    html?: boolean;
    delayShow?: number;
    delayHide?: number;
    hidden?: boolean;
    place?: 'top' | 'right' | 'bottom' | 'left'
}

const Tooltip = ({ children, html, delayShow, delayHide, hidden, id, place, text }: TooltipProps) => (
    <div className='tooltip' data-for={id} data-tip={text}>
        {children}
        {!hidden &&
            <ReactTooltip html={html} id={id} place={place} delayShow={delayShow ?? 0} delayHide={delayHide ?? 200} effect='solid' backgroundColor='#000028' />
        }
    </div>
);

export default Tooltip;
