import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';
import { Api, apiCall } from '.';

export const getEnvironment = () => apiCall(
    Api.isProduction(),
    async x => {
        (window as any).isProduction = x.data;
    },
    async () => { }
);

export const trackEvent = (event: IEventTelemetry) => {
    const applicationInsights: ApplicationInsights | null = (window as any).isProduction ? (window as any).appInsights : null;

    try {
        applicationInsights?.trackEvent(event);
    } catch (e) { }
};
