import { Reducer } from 'redux';
import { INITIAL_CALLER, INITIAL_LINE_VALUES, INITIAL_POWERS } from '../constants';
import { LineValues, LineData, Filter, CallerData, DtkQueryStrings, SpcData } from '../models';

export interface State {
    dtkData?: DtkQueryStrings;
    spcData?: SpcData;
    lineData: LineData[];
    lineValues: LineValues;
    callerData: CallerData;
    filter: Filter;
    hiddenIds: number[];
    newIds: number[];
    moreCardOpen: boolean;
    moreVoltageOpen: boolean;
    loading: boolean;
}

interface AddHiddenIdAction {
    type: 'ADD_HIDDEN_ID';
    id: number;
}

interface AddNewIdsAction {
    type: 'ADD_NEW_IDS';
    ids: number[];
}

interface ClearHiddenIdsAction {
    type: 'CLEAR_HIDDEN_IDS';
}

interface ClearNewIdsAction {
    type: 'CLEAR_NEW_IDS';
}

interface RemoveNewIdAction {
    type: 'REMOVE_NEW_ID';
    id: number;
}

interface SetDtkDataAction {
    type: 'SET_DTK_DATA';
    dtkData: DtkQueryStrings;
}

interface SetSpcDataAction {
    type: 'SET_SPC_DATA';
    spcData: SpcData;
}

interface SetFilterAction {
    type: 'SET_FILTER';
    filter: Filter;
}

interface SetLineDataAction {
    type: 'SET_LINE_DATA';
    lineData: LineData[];
}

interface SetLineValuesAction {
    type: 'SET_LINE_VALUES';
    lineValues: LineValues;
}

interface SetCallerDataAction {
    type: 'SET_CALLER_DATA';
    callerData: CallerData;
}

interface ToggleLoadingAction {
    type: 'TOGGLE_LOADING';
}

interface ToggleMoreCardAction {
    type: 'TOGGLE_MORE_CARD';
}

interface ToggleMoreVoltageAction {
    type: 'TOGGLE_MORE_VOLTAGE';
}

type KnownAction = SetCallerDataAction | ToggleLoadingAction | SetDtkDataAction | SetSpcDataAction | SetFilterAction | SetLineDataAction | AddHiddenIdAction | AddNewIdsAction | ClearHiddenIdsAction | ClearNewIdsAction | RemoveNewIdAction | SetLineValuesAction | ToggleMoreCardAction | ToggleMoreVoltageAction;

export const actionCreators = {
    addHiddenId: (id: number): AddHiddenIdAction => ({ type: 'ADD_HIDDEN_ID', id }),
    addNewIds: (ids: number[]): AddNewIdsAction => ({ type: 'ADD_NEW_IDS', ids }),
    clearHiddenIds: (): ClearHiddenIdsAction => ({ type: 'CLEAR_HIDDEN_IDS' }),
    clearNewIds: (): ClearNewIdsAction => ({ type: 'CLEAR_NEW_IDS' }),
    removeNewId: (id: number): RemoveNewIdAction => ({ type: 'REMOVE_NEW_ID', id }),
    setDtkData: (dtkData: DtkQueryStrings): SetDtkDataAction => ({ type: 'SET_DTK_DATA', dtkData }),
    setSpcData: (spcData: SpcData): SetSpcDataAction => ({ type: 'SET_SPC_DATA', spcData }),
    setFilter: (filter: Filter): SetFilterAction => ({ type: 'SET_FILTER', filter }),
    setLineData: (lineData: LineData[]): SetLineDataAction => ({ type: 'SET_LINE_DATA', lineData }),
    setLineValues: (lineValues: LineValues): SetLineValuesAction => ({ type: 'SET_LINE_VALUES', lineValues }),
    setCallerData: (callerData: CallerData): SetCallerDataAction => ({ type: 'SET_CALLER_DATA', callerData }),
    toggleLoading: (): ToggleLoadingAction => ({ type: 'TOGGLE_LOADING' }),
    toggleMoreCard: (): ToggleMoreCardAction => ({ type: 'TOGGLE_MORE_CARD' }),
    toggleMoreVoltage: (): ToggleMoreVoltageAction => ({ type: 'TOGGLE_MORE_VOLTAGE' })
};

export const reducer: Reducer<State, KnownAction> = (state, action) => {
    if (state === undefined) {
        return {
            lineData: [],
            newIds: [],
            hiddenIds: [],
            lineValues: INITIAL_LINE_VALUES,
            callerData: INITIAL_CALLER,
            filter: {
                powerMin: INITIAL_POWERS.powerMin,
                powerMax: INITIAL_POWERS.powerMax,
                isKwSelected: true
            },
            loading: false,
            moreCardOpen: false,
            moreVoltageOpen: false
        };
    }

    switch (action.type) {
        case 'SET_DTK_DATA':
            return { ...state, dtkData: action.dtkData };
        case 'SET_SPC_DATA':
            return { ...state, spcData: action.spcData };
        case 'SET_FILTER':
            return { ...state, filter: { ...state.filter, ...action.filter } };
        case 'SET_LINE_DATA':
            return { ...state, lineData: action.lineData };
        case 'ADD_HIDDEN_ID':
            return { ...state, hiddenIds: [...state.hiddenIds, action.id] };
        case 'ADD_NEW_IDS':
            return { ...state, newIds: [...state.newIds, ...action.ids] };
        case 'CLEAR_HIDDEN_IDS':
            return { ...state, hiddenIds: [] };
        case 'CLEAR_NEW_IDS':
            return { ...state, newIds: [] };
        case 'REMOVE_NEW_ID':
            return { ...state, newIds: state.newIds.filter(x => x !== action.id) };
        case 'SET_LINE_VALUES':
            return { ...state, lineValues: action.lineValues };
        case 'SET_CALLER_DATA':
            return { ...state, callerData: { ...state.callerData, ...action.callerData } };
        case 'TOGGLE_LOADING':
            return { ...state, loading: !state.loading };
        case 'TOGGLE_MORE_CARD':
            return { ...state, moreCardOpen: !state.moreCardOpen };
        case 'TOGGLE_MORE_VOLTAGE':
            return { ...state, moreVoltageOpen: !state.moreVoltageOpen };
        default:
            return state;
    }
};
