import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslations, CALLER_NAMES_FOR_REPLACEMENT, TIA_SCENARIO_RESULT_FORMAT } from '../../constants';
import { Language } from '../../enums';
import { ELBRIDGE_SCENARIO_RESULT_FORMAT } from '../../constants/ElbridgeScenarioParameters';
import { SHARED_SCENARIO_CONFIG_ID, SHARED_SCENARIO_UI_MODE, SPC_PROD_BASE_URL, SPC_TEST_BASE_URL } from '../../constants/SharedScenarioParameters';
import { useTranslate } from '../../hooks';
import { LineData } from '../../models';
import { isIframe, trackEvent } from '../../services';
import { ApplicationState } from '../../store';
import Button, { ButtonType } from '../common/Button';
import Icon, { IconType } from '../common/Icon';

interface LineItemConfigurationButtonProps {
    lineData: LineData;
    isDtkFirstScenario: boolean;
    responseUrl: string;
}

const LineItemConfigurationButton = ({ lineData, isDtkFirstScenario, responseUrl }: LineItemConfigurationButtonProps) => {
    const translate = useTranslate();
    const translations = getTranslations();
    const language = useSelector((state: ApplicationState) => state.translation.language);
    const callerData = useSelector((state: ApplicationState) => state.selector.callerData);
    const spcData = useSelector((state: ApplicationState) => state.selector.spcData);
    const [configureSelected, setConfigureSelected] = useState(false);
    const [configureButtonOpen, setConfigureButtonOpen] = useState(false);

    const windowAsAny = window as any;
    const configurationLinkBaseUrl = windowAsAny.isProduction ? SPC_PROD_BASE_URL : SPC_TEST_BASE_URL;

    const track = (name: string, properties: Record<string, unknown>) => trackEvent({ name: name, properties });

    const changeKmat = (kmat: string, callerName: string, isCallerDataFilled: boolean) => {
        switch (kmat.toUpperCase()) {
            case 'V90':
                kmat = 'SYS_V90';
                break;
            case 'S200':
                kmat = 'S_S200';
                break;
            case 'S210':
                kmat = 'S_S210';
                break;
            case 'S120':
                if (isCallerDataFilled && CALLER_NAMES_FOR_REPLACEMENT.find(a => a === callerName.toUpperCase())) {
                    kmat = 'S120_MC';
                }
                break;
        }

        return kmat;
    };

    const handleConfigureSelect = () => {
        track('Configure', { mlfb: lineData.mlfb });

        setConfigureButtonOpen(!configureSelected);
        setConfigureSelected(!configureButtonOpen);
    };

    const isCallerTia = () => callerData.CALLER.toLowerCase() === 'tia' || (callerData?.SCENARIO && callerData?.SCENARIO.toLowerCase() === 'tia');

    const isTia = useMemo(() => isCallerTia(), [callerData]);
    
    const isElbridgeScenario = () => {
        return Object.keys(callerData).filter(x => x !== 'VERSION' && x !== 'SCENARIO')
        .map(key => callerData[key as keyof typeof callerData]).find(a => a === '') === undefined;
    };

    const isElbridge = useMemo(() => isElbridgeScenario(), [callerData]);

    const getConfigurationLinkLanguage = () => Language[language].toUpperCase() === 'CZ' ? 'CS' : Language[language].toUpperCase() === 'CN' ? 'ZH' : Language[language].toLowerCase();

    const getConfigurationLink = (kmat: string) => {
        const { CALLER, LANGUAGE } = callerData;
        const newKmat = changeKmat(kmat, CALLER, isElbridge);
        const language = LANGUAGE ? LANGUAGE : getConfigurationLinkLanguage();
        
        if (spcData && spcData.configId.length > 0) {
            return `${configurationLinkBaseUrl}?` +
                `CONFIGID=${spcData.configId}` +
                `&REGION=${spcData.region ?? 'DE'}` +
                `&LANGUAGE=${spcData.language ?? 'EN'}` +
                `&PRODUCTID=${newKmat}`;
        } else {
            return `${configurationLinkBaseUrl}?CONFIGID=47&REGION=DE` +
                `&LANGUAGE=${language}` +
                `&PRODUCTID=${newKmat}`;
        }        
    };
    
    const callSpcWithFormData = (kmat: string) => {
        const form = document.createElement('form');
        const { CALLER, COUNTRY, HOOKURL, LANGUAGE } = callerData;
        const newKmat = changeKmat(kmat, CALLER, isElbridge);

        form.setAttribute('method', 'post');
        form.setAttribute('action', configurationLinkBaseUrl);
        form.setAttribute('target', '_self');

        form.appendChild(createFormItem('CONFIGID', SHARED_SCENARIO_CONFIG_ID));
        form.appendChild(createFormItem('LANGUAGE', LANGUAGE));
        form.appendChild(createFormItem('REGION', COUNTRY));
        form.appendChild(createFormItem('HOOKURL', HOOKURL));
        form.appendChild(createFormItem('UIMODE', SHARED_SCENARIO_UI_MODE));
        form.appendChild(createFormItem('RESULTFORMAT', isTia ? TIA_SCENARIO_RESULT_FORMAT : ELBRIDGE_SCENARIO_RESULT_FORMAT));
        form.appendChild(createFormItem('PRODUCTID', newKmat));
        
        if (isIframe && !isTia) {
            form.appendChild(createFormItem('HOOKTARGET', '_TOP'));
        }
        
        document.body.appendChild(form);

        form.submit();

        document.body.removeChild(form);
    };

    const createFormItem = (name: string, value: string) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;

        return input;
    };

    const trackCaller = () => {
        track('Configure', { mlfb: lineData.mlfb });

        callerData.CALLER && track('ElbridgeCaller', { caller: callerData.CALLER });
    };

    const renderConfigurationLink = (kmat: string, translateId: string) => (
        <div className='actions'>
            <Button type={ButtonType.Primary} onClick={() => (isElbridge ? callSpcWithFormData(kmat) : window.open(getConfigurationLink(kmat), '_self', 'noreferrer')) && trackCaller}>
                <Icon type={IconType.Configuration} />
                {translate((translations.lvconverters.item.configure as any)[translateId])}
            </Button>
        </div>
    );

    const renderConfigurationLinksForSpecialProduct = (firstProductKmat: string, firstProductTranslationId: string, secondProductKmat: string, secondProductTranslationId: string) => (
        <>
            <div className='actions'>
                <Button type={ButtonType.Primary} onClick={handleConfigureSelect}>
                    <Icon type={configureButtonOpen ? IconType.Minus : IconType.Plus} /> 
                    {translate(translations.lvconverters.item.configure.title)}
                </Button>
            </div>
            {
                configureSelected &&
                <>
                    {renderConfigurationLink(firstProductKmat, firstProductTranslationId)}
                    {renderConfigurationLink(secondProductKmat, secondProductTranslationId)}
                </>
            }
        </>
    );

    const getDataForDtk = (mlfb?: string) => !mlfb ? JSON.stringify({ 'orderNumber': lineData.mlfb }) : JSON.stringify({ 'orderNumber': mlfb });

    const renderButtonsForSpecialProduct = (firstProductName: string, firstProductMlfb: string, secondProductName: string, secondProductMlfb: string) => (
        <>
            <div className='actions'>
                <Button type={ButtonType.Primary} onClick={handleConfigureSelect}>
                    <Icon type={configureButtonOpen ? IconType.Minus : IconType.Plus} />
                    {translate(translations.lvconverters.item.configure.title)}
                </Button>
            </div>
            {
                configureSelected &&
                <>
                    {renderForm(false, firstProductName, firstProductMlfb)}
                    {renderForm(false, secondProductName, secondProductMlfb)}
                </>
            }
        </>
    );

    const renderForm = (addConfigurationLabel: boolean, title?: string, mlfb?: string) => (
        <form method='POST' encType='multipart/form-data' action={responseUrl}>
            <textarea hidden id='DATA' name='DATA' defaultValue={getDataForDtk(mlfb)} />
            <div className='actions'>
                <button className='button primary' type='submit'>
                    <Icon type={IconType.Configuration} />
                    {addConfigurationLabel && translate(translations.lvconverters.item.configure.title)}
                    {translate((translations.lvconverters.item.configure as any)[title ?? ''])}
                </button>
            </div>
        </form>
    );

    const renderDtkFirstScenarioConfiguration = () => {
        let form;

        switch (lineData.kmat) {
            case 'S120':
                form = renderButtonsForSpecialProduct('S120_MC', '6SL33101TE......', 'S120', '6SL3....T.......');
                break;
            case 'S210':
                form = renderConfigurationLink(lineData.kmat, 'title');
                break;
            case 'G115D':
                form = renderButtonsForSpecialProduct('SYS_G115D', '2KJ8...-2....-....', 'G115D', '6SL35...X.......');
                break;
            default:
                form = renderForm(true);
                break;
        }

        return form;
    };

    const renderConfiguration = () => {
        let configuration = renderConfigurationLink(lineData.kmat, 'title');
        const needToReplaceCallerName = CALLER_NAMES_FOR_REPLACEMENT.find(a => a === callerData.CALLER.toUpperCase());

        if (lineData.kmat === 'S120' && needToReplaceCallerName) {
            configuration = renderConfigurationLinksForSpecialProduct('S120_MC', 'S120_MC', 'S120', 'S120');
        }
        else if (lineData.kmat === 'G115D') {
            configuration = renderConfigurationLinksForSpecialProduct('SYS_G115D', 'SYS_G115D', 'G115D', 'G115D');
        }

        return configuration;
    };

    return (
        isDtkFirstScenario ? renderDtkFirstScenarioConfiguration() : renderConfiguration()
    );
};

export default LineItemConfigurationButton;
