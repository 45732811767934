import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getTranslations, KEEP_ALIVE_URL, RESULT_URL, CALLER, COUNTRY, HOOKURL, LANGUAGE, SCENARIO, VERSION, REGION, CONFIGID } from '../../constants';
import { useTranslate } from '../../hooks';
import { Api, apiCall } from '../../services';
import { actionCreators, ApplicationState } from '../../store';
import { SnackbarType } from '../common/Snackbar';
import Selector from './Selector';

const SelectorManager = () => {
    const dispatch = useDispatch();
    const urlSearchParameters = new URLSearchParams(useLocation().search);
    const translate = useTranslate();
    const translations = getTranslations();
    const filter = useSelector((state: ApplicationState) => state.selector.filter);
    const lineValues = useSelector((state: ApplicationState) => state.selector.lineValues);
    const [powers, setPowers] = useState<number[]>([]);

    useEffect(() => {
        (async () => {
            dispatch(actionCreators.toggleLoading());

            window.scrollTo(0, 0);
            
            const [keepAliveUrl, region, resultUrl, caller, country, hookUrl, language, scenario, version, configId] =
                [KEEP_ALIVE_URL, REGION, RESULT_URL, CALLER, COUNTRY, HOOKURL, LANGUAGE, SCENARIO, VERSION, CONFIGID].map(x => urlSearchParameters.get(x) ?? urlSearchParameters.get(x.toLowerCase()));

            dispatch(actionCreators.setCallerData({
                CALLER: caller ?? '',
                COUNTRY: country ?? '',
                HOOKURL: hookUrl ?? '',
                LANGUAGE: language ?? '',
                SCENARIO: scenario ?? '',
                VERSION: version ?? ''
            }));

            if (keepAliveUrl || REGION || resultUrl) {
                dispatch(actionCreators.setDtkData({
                    KEEPALIVEURL: keepAliveUrl ?? undefined,
                    REGION: region ?? undefined,
                    RESULTURL: resultUrl ?? undefined
                }));
            }

            if (configId) {
                dispatch(actionCreators.setSpcData({ configId, region: region ?? undefined, language: language ?? undefined }));
            }

            await apiCall(
                Api.loadValues(),
                async x => {
                    await dispatch(actionCreators.setLineValues(x.data));
                },
                async () => {
                    dispatch(actionCreators.addSnackbar({ text: translate(translations.error.lineValues), type: SnackbarType.Error }));
                }
            );

            await apiCall(
                Api.getAllLineItems(),
                async x => {
                    await dispatch(actionCreators.setLineData(x.data));
                },
                async () => {
                    dispatch(actionCreators.addSnackbar({ text: translate(translations.error.lineItems), type: SnackbarType.Error }));
                }
            );

            dispatch(actionCreators.toggleLoading());
        })();
    }, []);

    useEffect(() => {
        calculatePowers();
    }, [lineValues.minAndMaxPowers]);

    const calculatePowers = () => {
        const minPower: number = filter.isKwSelected ? lineValues.minAndMaxPowers.minPowerKw : lineValues.minAndMaxPowers.minPowerHp;
        const maxPower: number = filter.isKwSelected ? lineValues.minAndMaxPowers.maxPowerKw : lineValues.minAndMaxPowers.maxPowerHp;

        const powerArray: number[] = [];

        if (minPower < 1 && maxPower < 1) {
            return powerArray;
        }

        const startValue = (roundNumber(minPower, 0) + 0.5);
        const firstRangeLimit = 10;
        const secondRangeLimit = 100;

        powerArray.push(minPower);

        fillRange(powerArray, startValue, firstRangeLimit - 0.5, 0.5);

        if (secondRangeLimit < maxPower) {

            fillRange(powerArray, firstRangeLimit, secondRangeLimit - 5, 5);

            const thirdRangeLimit = 600;

            if (thirdRangeLimit < maxPower) {

                fillRange(powerArray, secondRangeLimit, thirdRangeLimit - 20, 20);

                const fourthRangeLimit = 1000;

                if (fourthRangeLimit < maxPower) {
                    fillRange(powerArray, thirdRangeLimit, fourthRangeLimit - 50, 50);
                    fillRange(powerArray, fourthRangeLimit, maxPower, 200);

                } else {
                    fillRange(powerArray, thirdRangeLimit, maxPower - 50, 50);
                }
            }
            else {
                fillRange(powerArray, secondRangeLimit, maxPower - 20, 20);
            }
        } else {
            fillRange(powerArray, firstRangeLimit, maxPower - 5, 5);
        }

        if (!powers.some(a => a === maxPower)) {
            powerArray.push(maxPower);
        }

        setPowers(powerArray);
    };

    const fillRange = (powerArray: number[], start: number, end: number, increaseNumber: number) => {
        for (let i = start; i <= end; i += increaseNumber) {
            powerArray.push(i);
        }
    };

    const roundNumber = (value: number, precision: number) => {
        const multiplier = Math.pow(10, precision);

        return Math.round(value * multiplier) / multiplier;
    };

    return (
        <Selector powers={powers} />
    );
};

export default SelectorManager;
