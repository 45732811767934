export enum Language {
    En,
    De,
    Es,
    Fr,
    It,
    Cz,
    Nl,
    Pl,
    Pt,
    Ru,
    Tr,
    Cn
}
