import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import SelectorManager from './ts/components/application/SelectorManager';

const routes = (
    <Switch>
        <Route path='/' component={SelectorManager} />
    </Switch>
);

export default routes;
